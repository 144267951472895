import {Injectable} from '@angular/core';
import {FeeStructuresService} from '../fee-structures.service';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {of} from 'rxjs';
import {select, Store} from '@ngrx/store';
import * as feeStructureActions from './fee-structure.actions';
import {catchError, map, mergeMap, withLatestFrom} from 'rxjs/operators';
import * as fromFeeStructure from '../state';
import {FeeStructure} from '../../../../billing/billing';

@Injectable()
export class FeeStructureEffects {

  constructor(
    private feeStructuresService: FeeStructuresService,
    private actions$: Actions,
    private store: Store<fromFeeStructure.State>
    ) {
  }

  @Effect()
  loadFeeStructures$ = this.actions$.pipe(
    ofType(feeStructureActions.Load),
    withLatestFrom(this.store.pipe(select(fromFeeStructure.getFeeStructures))),
    mergeMap<any, any>(() => {
      return this.feeStructuresService.getFeeStructures()
        .pipe(
        map(feeStructures => (feeStructureActions.LoadSuccess({feeStructures: feeStructures.data}))),
        catchError(error => of(feeStructureActions.LoadFail({error})))
      );
    })
  );

  @Effect()
  updateFeeStructure$ = this.actions$.pipe(
    ofType(feeStructureActions.UpdateFeeStructure),
    map(action => action.feeStructure),
    mergeMap((feeStructure: FeeStructure) => {
      return this.feeStructuresService.saveChargeUnit(feeStructure)
        .pipe(
          map((updatedFeeStructure: FeeStructure) => (feeStructureActions.UpdateFeeStructureSuccess({feeStructure: updatedFeeStructure}))),
          catchError((err: any) => {
            return of(feeStructureActions.UpdateFeeStructureFail({error: err.error.Message}));
          })
        );
    })
  );

  @Effect()
  removeFeeStructure$ = this.actions$.pipe(
    ofType(feeStructureActions.RemoveFeeStructure),
    map(action => action.feeStructure),
    mergeMap((feeStructure: FeeStructure) => {
      return this.feeStructuresService.removeChargeUnit(feeStructure)
        .pipe(
          map(() => (feeStructureActions.RemoveFeeStructureSuccess({feeStructure}))),
          catchError((err: any) => {
            return of(feeStructureActions.RemoveFeeStructureFail({error: err.error.Message}));
          })
        );
    })
  );

  @Effect()
  removeMultipleFeeStructure$ = this.actions$.pipe(
    ofType(feeStructureActions.RemoveMultipleFeeStructure),
    map(action => action.feeStructures),
    mergeMap((feeStructures: FeeStructure[]) => {
      return this.feeStructuresService.deleteFeeStructures(feeStructures)
        .pipe(
          map(() => (feeStructureActions.RemoveMultipleFeeStructureSuccess({feeStructures}))),
          catchError((err: any) => {
            return of(feeStructureActions.RemoveMultipleFeeStructureFail({error: err.error.Message}));
          })
        );
    })
  );
}

import {Component, HostListener, OnInit} from '@angular/core';
import {UserProfileService} from '../shared/services/auth/user-profile.service';
import {
  ChildActivationEnd,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router
} from '@angular/router';
import {AppLoadingService} from '../layout/app-loading.service';
import {ShortcutsService} from '../shared/services/shortcuts.service';
import {ClientPortalAccessService} from '../client-portal/client-portal-access.service';
import {Observable} from 'rxjs';
import {SearchService} from '../layout/search/search.service';
import {AutoLogoutService} from '../shared/services/auth/auto-logout.service';
import {State, Store} from '@ngrx/store';
import * as accountActions from '../accounts/state/account.actions';
import * as householdActions from '../households/state/household.actions';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {
  resolvingRoute: boolean;
  isSearchVisible: Observable<boolean>;

  isFirmUser = this.userProfileService.isFirmUser;
  constructor(private userProfileService: UserProfileService,
              private router: Router,
              private appLoadingService: AppLoadingService,
              private shortcutsService: ShortcutsService,
              private searchService: SearchService,
              private clientPortalAccessService: ClientPortalAccessService,
              private autoLogoutService: AutoLogoutService,
              private store: Store<State<any>>) {
  }
  // Use this to load an initialize date for the logged in application
  ngOnInit() {
    this.trackRouterState();

    this.appLoadingService.setAppFinishedLoading();

    if (!this.isFirmUser) {
      this.clientPortalAccessService.reroute();
    }

    this.isSearchVisible = this.searchService.isSearchVisible$;

    // Init state for shared accounts and households
    this.store.dispatch(accountActions.Load());
    this.store.dispatch(householdActions.Load());
  }

  // Show loading when switching and resolving routes
  trackRouterState() {
    this.router.events.subscribe((routerEvent) => {
      if (routerEvent instanceof NavigationStart) {
        this.resolvingRoute = true;
      }

      if (routerEvent instanceof NavigationEnd ||
        routerEvent instanceof NavigationError ||
        routerEvent instanceof NavigationCancel
      ) {
        this.resolvingRoute = false;
      }
    });
  }

  @HostListener('document:keydown', ['$event'])
  keydown(event) {
    if (!( /textarea|select|INPUT/i.test( event.target.nodeName )) &&
      event.target.type !== 'text' && event.target.contentEditable !== 'true') {
      this.shortcutsService.doAction(event);
    }
  }
}

import {createEntityAdapter, EntityState} from '@ngrx/entity';
import {AlertConditionSet} from '../alert-condition.type';
import {CallState} from '../../../../state/types';
import {EntityAdapter} from '@ngrx/entity/src/models';
import {InitialCallState} from '../../../../state/helpings/initial-call-state';
import {Action, createReducer, on} from '@ngrx/store';
import {
  CreateAlertCondition,
  CreateAlertConditionFail,
  CreateAlertConditionSuccess,
  LoadAlertConditions,
  LoadAlertConditionsFail,
  LoadAlertConditionsSuccess,
  RemoveAlertCondition,
  RemoveAlertConditionFail, RemoveAlertConditions, RemoveAlertConditionsFail, RemoveAlertConditionsSuccess,
  RemoveAlertConditionSuccess,
  UpdateAlertCondition,
  UpdateAlertConditionFail,
  UpdateAlertConditions,
  UpdateAlertConditionsFail,
  UpdateAlertConditionsSuccess,
  UpdateAlertConditionSuccess,
} from './alert-conditions.actions';

export interface AlertConditionState extends EntityState<AlertConditionSet> {
  callState: CallState;
  createCallState: CallState;
  updateCallState: CallState;
  updateManyCallState: CallState;
  removeCallState: CallState;
  removeMultipleCallState: CallState;
}

export const adapter: EntityAdapter<AlertConditionSet> = createEntityAdapter<AlertConditionSet>();

const initialState: AlertConditionState = adapter.getInitialState({
  callState: {...new InitialCallState()},
  createCallState: {...new InitialCallState()},
  updateCallState: {...new InitialCallState()},
  updateManyCallState: {...new InitialCallState()},
  removeCallState: {...new InitialCallState()},
  removeMultipleCallState: {...new InitialCallState()},
});

export function reducer(state: AlertConditionState | undefined, action: Action) {
  const reportPackageReducer = createReducer(
    initialState,
    on(LoadAlertConditions, (s) => {
      const callState = {...s.callState, loading: true};
      return {...s, callState};
    }),

    on(LoadAlertConditionsSuccess, (s, {alertConditions}) => {
      const callState = {loading: false, loaded: true, error: null};
      return {...adapter.setAll(alertConditions, s), callState};
    }),

    on(LoadAlertConditionsFail, (s, {error}) => {
      const callState = {loading: false, loaded: false, error};
      return {...state, callState};
    }),

    on(CreateAlertCondition, (s) => {
      const createCallState = {...s.createCallState, loading: true};
      return {...s, createCallState};
    }),

    on(CreateAlertConditionSuccess, (s, {alertCondition}) => {
      const createCallState = {loading: false};
      return {...adapter.addOne(alertCondition, s), createCallState};
    }),

    on(CreateAlertConditionFail, (s) => {
      const createCallState = {loading: false};
      return {...s, createCallState};
    }),

    on(UpdateAlertCondition, (s) => {
      const updateManyCallState = {...s.updateManyCallState, loading: true};
      return {...s, updateManyCallState};
    }),

    on(UpdateAlertConditionSuccess, (s, {alertCondition}) => {
      const updateCallState = {loading: false};
      return {...adapter.upsertOne(alertCondition, s), updateCallState};
    }),

    on(UpdateAlertConditionFail, (s) => {
      const updateCallState = {loading: false};
      return {...s, updateCallState};
    }),

    on(UpdateAlertConditions, (s) => {
      const updateManyCallState = {...s.updateManyCallState, loading: true};
      return {...s, updateManyCallState};
    }),

    on(UpdateAlertConditionsSuccess, (s, {alertConditions}) => {
      const updateManyCallState = {loading: false};
      const ids = alertConditions.map(f => f.id);
      return {...adapter.upsertMany(alertConditions, s), updateManyCallState};
    }),

    on(UpdateAlertConditionsFail, (s) => {
      const updateManyCallState = {loading: false};
      return {...s, updateManyCallState};
    }),

    on(RemoveAlertCondition, (s) => {
      const removeCallState = {...s.removeCallState, loading: true};
      return {...s, removeCallState};
    }),

    on(RemoveAlertConditionSuccess, (s, {alertCondition}) => {
      const removeCallState = {loading: false};
      return {...adapter.removeOne(alertCondition.id, s), removeCallState};
    }),

    on(RemoveAlertConditionFail, (s) => {
      const removeCallState = {loading: false};
      return {...s, removeCallState};
    }),

    on(RemoveAlertConditions, (s) => {
      const removeMultipleCallState = {...s.removeMultipleCallState, loading: true};
      return {...s, removeMultipleCallState};
    }),

    on(RemoveAlertConditionsSuccess, (s, {alertConditions}) => {
      const removeMultipleCallState = {loading: false};
      const ids = alertConditions.map(f => f.id);
      return {...adapter.removeMany(ids, s), removeMultipleCallState};
    }),

    on(RemoveAlertConditionSuccess, (s) => {
      const removeMultipleCallState = {loading: false};
      return {...s, removeMultipleCallState};
    }),
  );

  return reportPackageReducer(state, action);
}

const {
  selectAll,
} = adapter.getSelectors();

// select the array of users
export const selectAllAlertConditions = selectAll;

import {createAction, props} from '@ngrx/store';
import {Account} from '../account';
import {Household} from '../../households/household';

export const Load = createAction('[Accounts] Load accounts');
export const LoadSuccess = createAction('[Accounts] Load accounts success', props<{accounts: Account[]}>());
export const LoadFail = createAction('[Accounts] Load accounts fail', props<{error: string}>());
export const UpdateAccount = createAction('[Accounts] update account', props<{account: Account}>());
export const UpdateAccountSuccess = createAction(
  '[Accounts] update account Success',
  props<{account: Account}>());
export const UpdateAccountFail = createAction('[Accounts] update account Fail', props<{error: string}>());
export const UpdateAccounts = createAction('[Accounts] update accounts', props<{accounts: Account[]}>());
export const UpdateAccountsSuccess = createAction(
  '[Accounts] update accounts success', props<{accounts: Account[]}>());
export const UpdateAccountsFail = createAction(
  '[Accounts] update accounts failed', props<{error: string}>());

export const RemapAccounts = createAction(
  '[Accounts] remap accounts',
  props<{
    accounts: Account[],
    household: Household
  }>());
export const RemapAccountsSuccess = createAction(
  '[Accounts] remap accounts success', props<{
    accounts: Account[],
    household: Household
  }>());
export const RemapAccountsFail = createAction(
  '[Accounts] remap accounts failed', props<{error: string}>());

<nav style="position: fixed; z-index: 500; width: 100%;">
    <mat-toolbar color="primary">
      <mat-toolbar-row class="bottom-nav">
        <div class="navbar-container">
          <div class="nav-logo-container">
            <span class="helper"></span>

            <a [routerLink]="['/dashboard']" *ngIf="!hasLogo() && isFirmUser()">
              <img class="brand-logo" src="assets/images/Bridge_Black.jpg" alt="Dashboard">
            </a>

            <a  [routerLink]="['/dashboard']" *ngIf="hasLogo() && isFirmUser()">
              <img class="brand-logo" [src]="getLogoUrl()" alt="Dashboard">
            </a>

            <a  [routerLink]="['/cp/main']" *ngIf="!hasLogo() && isEndClient()">
              <img class="brand-logo" src="assets/images/Bridge_Black_White.png" alt="Dashboard">
            </a>

            <a  [routerLink]="['/cp/main']" *ngIf="hasLogo() && isEndClient()">
              <img class="brand-logo" [src]="getLogoUrl()" alt="Dashboard">
            </a>
          </div>

          <span style="display: flex; flex: 1"></span>

          <div class="firm-user-nav-container" *ngIf="isFirmUser()">
            <button mat-button
                    [class.active]="isActiveRoute('/dashboard')"
                    routerLink="/dashboard">
              <i class="far fa-th-large prefix-icon"></i>
              Dashboard
            </button>

            <button mat-button
                    *ngIf="uxAccessService.canAccessPermissionSetOne && uxAccessService.canAccessPermissionSetFour"
                    [class.active]="isActiveRoute('/revenue')"
                    routerLink="/revenue/billing">
              <i class="far fa-usd-square prefix-icon"></i>
              Revenue
            </button>

            <button mat-button
                    [class.active]="isActiveRoute('/reporting')"
                    routerLink="/reporting/entity-summary/">
              <i class="far fa-chart-pie-alt prefix-icon"></i>
              Reporting
            </button>

            <button mat-button
                    [class.active]="isActiveRoute('/clients')"
                    routerLink="/clients/list">
              <i class="far fa-user-friends prefix-icon"></i>
              Clients
            </button>

            <button mat-button
                    *ngIf="showInvestmentsPage()"
                    [class.active]="isActiveRoute('/investment-management')"
                    routerLink="/investment-management/strategies">
              <i class="far fa-chart-line prefix-icon"></i>
              Investment Models
            </button>

            <button mat-button
                    [class.active]="isActiveRoute('/data-management')"
                    routerLink="/data-management"
            >
              <i class="far fa-database prefix-icon"></i>
              Data Management
            </button>

            <button mat-button
                    *ngIf="isFirmUser() && uxAccessService.canAccessPermissionSetOne"
                    [class.active]="isActiveRoute('/insights')"
                    routerLink="/insights/dashboard">
              <i class="far fa-analytics prefix-icon"></i>
              Insights
            </button>
          </div>

          <button mat-button
                  [matMenuTriggerFor]="navMenu"
                  class="user-dropdown-btn no-hover-effect"
                  disableRipple>
            <span>{{ userFirstName ? userFirstName : 'Account' }}</span>
            <mat-icon [inline]="true" style="font-size: 24px;">arrow_drop_down</mat-icon>
          </button>


          <mat-menu #navMenu="matMenu">
            <button *ngIf="isFirmUser() && (uxAccessService.canAccessPermissionSetOne || !uxAccessService.canAccessPermissionSetThree)" mat-menu-item class="mat-menu-button" routerLink="/settings/firm-settings">
              <i class="far fa-cog"></i>
              <span>Settings</span>
            </button>

            <button mat-menu-item class="mat-menu-button" (click)="logout()">
              <i class="far fa-sign-out"></i>
              Logout
            </button>
          </mat-menu>

          <button mat-icon-button
                  *ngIf="isFirmUser()"
                  [ngClass]="{ 'open': sidenav.opened }"
                  (click)="sidenav.toggle()">
            <i class="fas fa-bars" style="font-size: 20px;"></i>
          </button>

<!--          TODO: Figure out how to make this dynamically change color based on user input primary color-->
<!--          <div *ngIf="isFirmUser()" class="sidenav-container">-->
<!--            <div class="hamburger-btn"-->
<!--                 [ngClass]="{ 'open': sidenav.opened }"-->
<!--                 (click)="sidenav.toggle()">-->
<!--              <div class="hamburger"></div>-->
<!--            </div>-->
<!--          </div>-->
        </div>

      </mat-toolbar-row>
    </mat-toolbar>
</nav>


<mat-sidenav-container [hasBackdrop]="true">
  <mat-sidenav #sidenav [mode]="'over'" [fixedInViewport]="true" [position]="'end'" class="sidenav">
    <div class="sidenav__btn-container">
      <div style="display: flex; align-items: center; justify-content: center; width: 100%;">

        <div style="display: flex; align-items: center; justify-content: center;">
          <img class="integration-logo" style="border-radius: 4px;" *ngIf="currentSection === 'ATLAS_ACTIVITY'" src="../../../assets/images/favicon/apple-icon.png" alt="bridge">

          <img class="integration-logo" *ngIf="currentSection === 'WEALTHBOX'" src="../../../assets/images/wealthbox-icon&#45;&#45;blue.png" alt="wealthbox">

          <div class="vertical-line"></div>

          <div>
            <label for="integrations-select" class="select-label">
              Integrations
            </label>

            <div class="select">
              <select class="integrations-select" id="integrations-select" (change)="togglePanel($event)">
                <option value="ATLAS_ACTIVITY">Insights & Activity</option>
                <option value="WEALTHBOX" [disabled]="!(wealthboxEnabled() | async)">Wealthbox</option>
              </select>
              <span class="focus"></span>
            </div>

<!--            <label for="integrations-select" class="select-label">Integrations</label>-->

<!--            <select id="integrations-select" (change)="togglePanel($event)">-->
<!--              <option value="ATLAS_ACTIVITY">Insights & Activity</option>-->
<!--              <option value="WEALTHBOX">Wealthbox</option>-->
<!--            </select>-->


          </div>
        </div>
      </div>
    </div>

    <app-side-panel></app-side-panel>
  </mat-sidenav>
</mat-sidenav-container>

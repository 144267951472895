import {Account} from '../account';
import * as AccountActions from './account.actions';
import {createEntityAdapter, EntityState} from '@ngrx/entity';
import {EntityAdapter} from '@ngrx/entity/src/models';
import {Action, createReducer, on} from '@ngrx/store';
import {CallState} from '../../state/types';
import {InitialCallState} from '../../state/helpings/initial-call-state';

export interface AccountState extends EntityState<Account> {
  callState: CallState;
  updateCallState: CallState;
  updateManyCallState: CallState;
  remapCallState: CallState;
}
export const adapter: EntityAdapter<Account> = createEntityAdapter<Account>();

const initialState: AccountState = adapter.getInitialState({
  callState: {...new InitialCallState()},
  updateCallState: {...new InitialCallState()},
  updateManyCallState: {...new InitialCallState()},
  remapCallState: {...new InitialCallState()}
});

export function reducer(state: AccountState | undefined, action: Action) {

  const accountReducer = createReducer(
    initialState,
    on(AccountActions.Load, (state) => {
      const callState = {...state.callState, loading: true};
      return {...state, callState};
    }),

    on(AccountActions.LoadSuccess, (state, {accounts}) => {
      const callState = {loading: false, loaded: true, error: null};
      return {...adapter.addAll(accounts, state), callState};
    }),

    on(AccountActions.LoadFail, (state, {error}) => {
      const callState = {loading: false, loaded: false, error};
      return {...state, callState};
    }),

    on(AccountActions.UpdateAccount, (state) => {
      const updateCallState = {...state.updateCallState, loading: true};
      return {...state, updateCallState};
    }),

    on(AccountActions.UpdateAccountSuccess, (state, {account}) => {
      const updateCallState = {loading: false};
      return {...adapter.upsertOne(account, state), updateCallState};
    }),

    on(AccountActions.UpdateAccountFail, (state) => {
      const updateCallState = {loading: false};
      return {...state, updateCallState};
    }),

    on(AccountActions.UpdateAccounts, (state) => {
      const updateManyCallState = {...state.updateManyCallState, loading: true};
      return {...state, updateManyCallState};
    }),

    on(AccountActions.UpdateAccountsSuccess, (state, {accounts}) => {
      const updateManyCallState = {loading: false};
      const ids = accounts.map(f => f.id);
      return {...adapter.upsertMany(accounts, state), updateManyCallState};
    }),

    on(AccountActions.UpdateAccountsFail, (state) => {
      const updateManyCallState = {loading: false};
      return {...state, updateManyCallState};
    }),

    on(AccountActions.RemapAccounts, (state) => {
      const remapCallState = {...state.remapCallState, loading: true};
      return {...state, remapCallState};
    }),

    on(AccountActions.RemapAccountsSuccess, (state, {accounts, household}) => {
      const remapCallState = {loading: false};

      accounts.forEach(a => {
        a.household_id = household.id;
      });

      return {...adapter.upsertMany(accounts, state), remapCallState};
    }),

    on(AccountActions.RemapAccountsFail, (state) => {
      const remapCallState = {loading: false};
      return {...state, remapCallState};
    })
  );
  return accountReducer(state, action);
}

// get the selectors
const {
  selectAll,
} = adapter.getSelectors();

// select the array of users
export const selectAllAccounts = selectAll;


import {Component, OnDestroy, OnInit} from '@angular/core';
import {WealthboxSettingsService} from './wealthbox-settings.service';
import {WealthboxService} from '../wealthbox.service';
import {SidePanelService} from '../../../../layout/side-panel/side-panel.service';
import {State, Store} from '@ngrx/store';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Subscription} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {AuthSource} from '../../auth-sources/auth-source.type';
import {UserProfileService} from '../../../../shared/services/auth/user-profile.service';
import {
  CreateAuthSource, CreateAuthSourceFail,
  CreateAuthSourceSuccess, RemoveAuthSource, RemoveAuthSourceFail, RemoveAuthSourceSuccess,
  UpdateAuthSource, UpdateAuthSourceFail, UpdateAuthSourceSuccess
} from '../../auth-sources/state/auth-sources.actions';
import {Actions, ofType} from '@ngrx/effects';
import {NotificationService} from '../../../../shared/services/ui/notification.service';

@Component({
  selector: 'app-wealthbox-settings',
  templateUrl: './wealthbox-settings.component.html',
})
export class WealthboxSettingsComponent implements OnInit, OnDestroy {

  loading = true;

  statusSubscription: Subscription;

  configurationSubscription: Subscription;

  config: AuthSource;
  wealthboxConfigForm: FormGroup;

  creating = false;
  saving = false;

  constructor(
    private wealthboxSettingsService: WealthboxSettingsService,
    public wealthboxService: WealthboxService,
    private sidePanelState: SidePanelService,
    private store: Store<State<any>>,
    private fb: FormBuilder,
    private userProfileService: UserProfileService,
    private actions$: Actions,
    private notificationService: NotificationService,
  ) {

    this.configurationSubscription = this.wealthboxSettingsService.configuration$.pipe(
      catchError(e => {
        // TODO: Error handling
        console.log(e);

        this.loading = false;
        return e;
      })
    ).subscribe((config: AuthSource) => {
      if (!config) {
       this.creating = true;

       this.config = {
         auth_credentials: {},
         expires_at_utc: '',
         firm_id: userProfileService.userProfile.firm_id,
         user_id: userProfileService.userProfile.user_id,
         source: 'Wealthbox',
         key: ''
       };
      } else {
        this.creating = false;
        this.config = config;
      }

      this.wealthboxConfigForm = this.fb.group({
        apiKey: [this.config.key, Validators.required]
      });

      this.loading = false;
    });
  }

  ngOnInit() {
    this.statusSubscription = this.actions$.pipe(
      ofType(
        CreateAuthSourceSuccess,
        CreateAuthSourceFail,
        UpdateAuthSourceSuccess,
        UpdateAuthSourceFail,
        RemoveAuthSourceSuccess,
        RemoveAuthSourceFail
      )
    ).subscribe((a) => {
      switch (a.type) {
        case CreateAuthSourceSuccess.type:
          this.creating = false;
          this.config = a.authSource;
          this.notificationService.showSuccessNotification('Wealthbox integration enabled successfully.');
          break;
        case CreateAuthSourceFail.type:
          this.notificationService.showErrorNotification('Error initiating wealthbox integration');
          break;
        case UpdateAuthSourceSuccess.type:
          this.config = a.authSource;
          this.notificationService.showSuccessNotification('Wealthbox integration updated successfully');
          break;
        case UpdateAuthSourceFail.type:
          this.notificationService.showErrorNotification('Error updating wealthbox integration key');
          break;
        case RemoveAuthSourceFail.type:
          this.notificationService.showErrorNotification(('Error removing the wealthbox API Key'));
          break;
        case RemoveAuthSourceSuccess.type:
          this.creating = true;
          this.config = {
            auth_credentials: {},
            expires_at_utc: '',
            firm_id: this.userProfileService.userProfile.firm_id,
            user_id: this.userProfileService.userProfile.user_id,
            source: 'Wealthbox',
            key: ''
          };
          this.wealthboxConfigForm.setValue({apiKey: ''});

          this.notificationService.showSuccessNotification('Wealthox integration disabled successfully');
          break;
      }

      this.saving = false;
    });
  }

  ngOnDestroy() {
    this.statusSubscription.unsubscribe();
    this.configurationSubscription.unsubscribe();
  }

  save = () => {
    this.saving = true;

    if (!this.wealthboxConfigForm.valid) {
      return;
    }

    this.config.key = this.wealthboxConfigForm.get('apiKey').value;

    if (this.creating) {
      this.store.dispatch(CreateAuthSource({authSource: this.config}));
    } else {
      this.store.dispatch(UpdateAuthSource({authSource: this.config}));
    }
  }

  remove = () => {
    this.saving = true;
    this.store.dispatch(RemoveAuthSource({authSource: this.config}));
  }
}

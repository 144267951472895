import {Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {
  CreateReportPackage,
  CreateReportPackageFail,
  CreateReportPackageSuccess,
  LoadReportPackages,
  LoadReportPackagesFail,
  LoadReportPackagesSuccess,
  RemoveReportPackage,
  RemoveReportPackageFail, RemoveReportPackages, RemoveReportPackagesFail, RemoveReportPackagesSuccess,
  RemoveReportPackageSuccess,
  UpdateReportPackage,
  UpdateReportPackageFail,
  UpdateReportPackages,
  UpdateReportPackagesFail,
  UpdateReportPackagesSuccess,
  UpdateReportPackageSuccess,
} from './report-packages.actions';
import {catchError, map, mergeMap, take, withLatestFrom} from 'rxjs/operators';
import {of} from 'rxjs';
import {getReportPackages, State} from './index';
import {ReportPackage} from '../report-package';
import {ReportPackagesService} from '../report-packages.service';
@Injectable()
export class ReportPackagesEffects {
  constructor(
    private reportPackagesService: ReportPackagesService,
    private actions$: Actions,
    private store: Store<State>
  ) {}

  @Effect()
  loadReportPackages$ = this.actions$.pipe(
    ofType(LoadReportPackages),
    withLatestFrom(this.store.pipe(select(getReportPackages))),
    mergeMap(() => this.reportPackagesService.getReportingPackages().pipe(
      map((resp) => LoadReportPackagesSuccess({reportPackages: resp})),
      catchError((err) => of(LoadReportPackagesFail({error: err.error})))
    )),
    take(1),
  );

  @Effect()
  createReportPackage$ = this.actions$.pipe(
    ofType(CreateReportPackage),
    map(action => action.reportPackage),
    mergeMap((reportPackage) => {
      return this.reportPackagesService.createReportingPackage(reportPackage).pipe(
        map((createdReportPackage) => CreateReportPackageSuccess({reportPackage: createdReportPackage})),
        catchError((err) => {
          return of(CreateReportPackageFail({error: err.error}));
        })
      );
    })
  );

  @Effect()
  updateReportPackage$ = this.actions$.pipe(
    ofType(UpdateReportPackage),
    map(action => action.reportPackage),
    mergeMap((reportPackage: ReportPackage) => {
      return this.reportPackagesService.updateReportingPackage(reportPackage).pipe(
        map((updatedPackage: ReportPackage) => UpdateReportPackageSuccess({reportPackage: updatedPackage})),
        catchError((err) => {
          return of(UpdateReportPackageFail({error: err.error}));
        })
      );
    })
  );

  @Effect()
  updateReportPackages$ = this.actions$.pipe(
    ofType(UpdateReportPackages),
    map(action => action.reportPackages),
    mergeMap((reportPackages: ReportPackage[]) => {
      return this.reportPackagesService.updateReportingPackages(reportPackages).pipe(
        map((updatedPackages: ReportPackage[]) => UpdateReportPackagesSuccess({reportPackages: updatedPackages})),
        catchError((err) => {
          return of (UpdateReportPackagesFail({error: err.error}));
        })
      );
    })
  );

  @Effect()
  removeReportPackage$ = this.actions$.pipe(
    ofType(RemoveReportPackage),
    map(action => action.reportPackage),
    mergeMap((reportPackage: ReportPackage) => {
      return this.reportPackagesService.removeReportPackage(reportPackage).pipe(
        map(() => RemoveReportPackageSuccess({reportPackage})),
        catchError((err) => {
          return of(RemoveReportPackageFail({error: err.error}));
        })
      );
    })
  );

  @Effect()
  removeReportPackages$ = this.actions$.pipe(
    ofType(RemoveReportPackages),
    map(action => action.reportPackages),
    mergeMap((reportPackages: ReportPackage[]) => {
      return this.reportPackagesService.removeReportPackages(reportPackages).pipe(
        map(() => RemoveReportPackagesSuccess({reportPackages})),
        catchError((err) => {
          return of(RemoveReportPackagesFail({error: err.error}));
        })
      );
    })
  );
}

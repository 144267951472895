import {AfterViewChecked, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UserProfileService} from '../../../shared/services/auth/user-profile.service';
import {InvoiceService} from '../invoice.service';
import {Invoice} from '../invoice';
import {DxDataGridComponent} from 'devextreme-angular/ui/data-grid';
import {NotificationService} from '../../../shared/services/ui/notification.service';
import {JobsService} from '../../../shared/services/jobs.service';
import {AppConstants} from '../../../app-constants';
import _get from 'lodash-es/get';
import {DatePipe} from '@angular/common';
import {Moment} from 'moment';
import {ActivityService} from '../../../settings/integrations/atlas/activity.service';
import {BillingReport} from '../../../shared/type_classes/billing-report';
import {Subject, Subscription} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {GridService} from '../../../clients/grid.service';
import {MatDialog} from '@angular/material/dialog';
import {SendClientEmailComponent} from '../../../client-inbox/send-client-email/send-client-email.component';
import {ChurnZeroService} from '../../../shared/services/churn-zero.service';

@Component({
  selector: 'app-invoice-list',
  templateUrl: './invoice-list.component.html',
  providers: [DatePipe]
})
export class InvoiceListComponent implements OnInit, AfterViewChecked, OnDestroy {
  invoices: Invoice[];
  selected: Invoice[] = [];
  isGrouped: boolean;
  private downloadType = AppConstants.DOWNLOAD_TYPE;

  @ViewChild('dataGrid') dataGrid: DxDataGridComponent;
  private viewChecked: boolean;
  isEndClientUser: any;
  isFirmUser: any;
  filters: { household: string; selectedDate: Moment | null };

  lastBillingReport: BillingReport;
  reportSubscription: Subscription;

  mostRecentBillingDate: string;

  searchTerm$ = new Subject<string>();
  loading = false;

  showAll = false;

  constructor(
    private userProfileService: UserProfileService,
    private invoiceService: InvoiceService,
    private notification: NotificationService,
    private jobsService: JobsService,
    private datePipe: DatePipe,
    private activityService: ActivityService,
    private route: ActivatedRoute,
    private gridService: GridService,
    private dialog: MatDialog,
    private churnZeroService: ChurnZeroService) {
    this.churnZeroService.trackModule('Invoices');

    this.filters = {
      selectedDate: null,
      household: ''
    };
  }

  ngOnInit() {
    this.isEndClientUser = this.userProfileService.isEndClientUser.bind(this);
    this.isFirmUser = this.userProfileService.isFirmUser.bind(this);

    this.reportSubscription = this.activityService.lastBillingReport$.asObservable().subscribe(report => {
      this.lastBillingReport = report;
    });

    this.invoices = this.route.snapshot.data.invoices;

    this.mostRecentBillingDate = this.invoices[0].created_date;

    this.searchTerm$.asObservable().subscribe(searchTerm => {
      this.dataGrid.instance.searchByText(searchTerm);
    });
  }

  getFees(invoice: Invoice) {
    if (invoice.is_rebate_invoice) {
      return -1 * parseFloat(invoice.period_debit);
    }
    return parseFloat(invoice.period_debit);
  }

  getCustodianBilled(invoice: Invoice) {
    if (invoice.is_rebate_invoice) {
      return -1 * parseFloat(invoice.custodian_billed_period_debit);
    }
    return parseFloat(invoice.custodian_billed_period_debit);
  }

  ngAfterViewChecked() {
    this.viewChecked = true;
  }

  ngOnDestroy() {
    this.reportSubscription.unsubscribe();
    this.gridService.clearSearchText('invoices');
  }

  loadAllInvoices() {
    this.loading = true;

    this.invoiceService.getInvoices().then((invoices) => {
      this.invoices = invoices;
      this.showAll = true;
    }).finally(() => {
      this.loading = false;
    });
  }

  nInvoices() {
    return this.invoices && this.invoices.length;
  }

  onSelection(selectedItems) {
    this.selected = selectedItems.selectedRowsData;
  }

  onContentReady(e) {
    this.isGrouped = !!e.component.columnOption('groupIndex:0');
  }

  downloadPDFInvoice(invoice: Invoice) {
    this.invoiceService.downloadPDFInvoice(invoice);
  }

  displayNoAccounts = (invoice: Invoice) => {
    return invoice.account_ids.length;
  }

  downloadSelectedInvoices() {
    if (this.selected.length === 0) {
      this.notification.showInfoNotification('No invoices are selected');
      return;
    }

    const ids = this.selected.map(s => s.id);
    this.jobsService.startBulkDownload(this.downloadType.DOWNLOAD_TYPE_INVOICE, ids)
      .then(() => {
        this.notification.showSuccessNotification('Preparing your invoices. They will be automatically download when complete');
      })
      .catch((err) => {
        this.notification.showErrorNotification('Failed to download invoices');

        if (err.status === 403) {
          this.notification.showErrorNotification(err.data.message);
        } else if (err.status > 0) {
          this.notification.showErrorNotification('There was a problem bulk downloading reports/invoices. ' +
            'Please contact support: support@bridgeft.com.');
        }
      });
  }

  private isMatchSearchFilter(invoice: Invoice): boolean {
    const billingGroupName = _get(invoice, 'billing_group_serialized[0].name', '').toLowerCase();
    return billingGroupName.startsWith(this.filters.household.toLowerCase());
  }

  private isMatchDateFilter(invoice): boolean {
    return !this.filters.selectedDate || invoice.date.unix() === this.filters.selectedDate.unix();
  }

  getFilteredInvoices(): Invoice[] {
    return this.invoices.filter(invoice => this.isMatchSearchFilter(invoice) && this.isMatchDateFilter(invoice));
  }

  displayDateOption = (date) => {
    return this.datePipe.transform(date, 'MMMM of yyyy');
  }

  getGroupName = (invoice: Invoice) => {
    if (invoice.billing_group_serialized) {
      if (invoice.billing_group_serialized[0]) {
        return invoice.billing_group_serialized[0].name;
      } else {
        return '';
      }
    }
  }

  downloadLastBillingRunInvoices() {
    this.activityService.getBillingReportActivity().subscribe((report) => {
      this.jobsService.startBulkDownload(this.downloadType.DOWNLOAD_TYPE_INVOICE, report.invoice_ids).then(() => {
        this.notification.showSuccessNotification('Preparing your invoices. They will be automatically download when complete');
      })
        .catch((err) => {
          this.notification.showErrorNotification('Failed to download invoices');

          if (err.status === 403) {
            this.notification.showErrorNotification(err.data.message);
          } else if (err.status > 0) {
            this.notification.showErrorNotification('There was a problem bulk downloading reports/invoices. ' +
              'Please contact support: support@bridgeft.com.');
          }
        });
    });
  }

  sendInvoices() {
    if (!this.selected || !this.selected.length) {
      this.notification.showInfoNotification('No invoices selected');
      return;
    }

    this.dialog.open(SendClientEmailComponent, {data: {messageType: 'I', sendables: this.selected}})
      .afterClosed().toPromise().then((sent) => {
        if (sent) {
          this.dataGrid.instance.clearSelection();
        }
    });
  }
}

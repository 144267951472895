import { Injectable } from '@angular/core';
import {Transaction, LucaTransfer} from '../../../shared/type_classes/luca/transaction';
import {BehaviorSubject, combineLatest, from, Observable, of} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Account} from '../../../accounts/account';
import {catchError, map, shareReplay, switchMap, tap} from 'rxjs/operators';
import {AccountService} from '../../../accounts/account.service';
import {CurrentBalanceService} from '../../../shared/services/current-balance.service';
import * as moment from 'moment';
import {BillingReport} from '../../../shared/type_classes/billing-report';
import {BillingReportService} from '../../../billing/billing-report.service';
import {InvoiceService} from '../../../billing/invoices/invoice.service';

/**
 * Service to consolidate different sections of atlas into an activity stream
 * Transactions, Tasks,
 */

@Injectable({
  providedIn: 'root'
})
export class ActivityService {

  transactionsCache: any;

  // Observables to notify sidebar of new data
  latestPDFReports$ = new BehaviorSubject(null);
  lastBillingReport$ = new BehaviorSubject(null);

  constructor(private http: HttpClient,
              private accountService: AccountService,
              private currentBalanceService: CurrentBalanceService,
              private billingReportService: BillingReportService,
              private invoiceService: InvoiceService) { }

  getNewAccounts(): Observable<Account[]> {
    return combineLatest([this.accountService.accounts$, this.currentBalanceService.lastDate$]).pipe(
      map(([accounts, lastDate]) => {
        return accounts.filter(account => this.accountOpenedInLastWeek(account, lastDate));
      })
    );
  }

  private accountOpenedInLastWeek(account: Account, lastDate: string) {
    const currentDate = moment(lastDate);
    const lastWeekDate = currentDate.clone().subtract(7, 'days').startOf('day');

    return moment(account.first_funded_date).isAfter(lastWeekDate);
  }

  getTransactions(): Observable<Transaction[]> {
    if (this.transactionsCache) {
      return of(this.transactionsCache);
    }

    const lucaUrl = `${environment.apiV2Url}/data/luca/transfer/filter?pager.limit=300`;

    const currentDate = moment();
    const lastWeekDate = currentDate.clone().subtract(7, 'days').startOf('day');

    const data = {
      transaction_date: {
        any_or_all: 'all',
        conditions: [{
          value: currentDate.format('YYYY-MM-DD'),
          op: 'lt',
        }, {
          value: lastWeekDate.format('YYYY-MM-DD'),
          op: 'gte',
        }]
      }
    };

    const params = {
      household_id: null
    };

    // TODO: Remove reference to this, use luca only
    const transactions$ = from(Promise.resolve([]));
    const lucaTransactions$ = this.http.post(lucaUrl, data).pipe(map((resp: {data: LucaTransfer[]}) => resp.data));

    const accounts$ = this.accountService.accounts$;
    const lastDate$ = this.currentBalanceService.lastDate$;

    return combineLatest([transactions$, lucaTransactions$, accounts$, lastDate$]).pipe(
      map(([transactions, lucaTransactions, accounts]) => {
        return this.buildTransactions(transactions, lucaTransactions, accounts);
      }),
      tap((transactions) => {
        this.transactionsCache = transactions;
      }),
      shareReplay(1)
    );
  }

  private buildTransactions(transactions: Transaction[], lucaTransactions: LucaTransfer[], accounts: Account[]): Transaction[] {

    const consolidatedTransactions = [];

    lucaTransactions = lucaTransactions.filter(transaction => (transaction.type === 'DEP' || transaction.type === 'WITH'));
    const lucaAccountIDs = lucaTransactions.map(transaction => transaction.account_id);

    // Filter transactions that are not RCV or DLV code
    transactions = transactions.filter(transaction => {
      return (transaction.transaction_code === 'RCV' || transaction.transaction_code === 'DLV') && !lucaAccountIDs.includes(transaction.account_id);
    });

    // Add account name to transaction
    transactions.forEach(transaction => {
      const account = accounts.find(a => transaction.account_id === a.id);
      if (!account) {
        return;
      }
      transaction.account_name = account ? account.display_name : '';
      transaction.amount = transaction.amount.replace('-', '');

      consolidatedTransactions.push(transaction);
    });

    lucaTransactions.forEach(transaction => {
      const account = accounts.find(a => transaction.account_id === a.id);
      if (!account) {
        return;
      }
      transaction.account_name = account.display_name;
      transaction.amount = transaction.abs_units * transaction.unit_price;

      consolidatedTransactions.push(transaction);
    });

    // TODO: Roll up amounts on the same security ID
    return consolidatedTransactions.filter((transaction) => parseFloat(transaction.amount) > 2500 && parseFloat(transaction.amount) !== 0);
  }

  // Billing Report, and invoices
  getBillingReportActivity(): Observable<BillingReport> {
    return this.billingReportService.getMostRecentBillingReport().pipe(
      switchMap(report => {
        if (!report) {
          return of(null);
        }
        return this.invoiceService.getInvoiceIDsFromBillingReportID(report.id).pipe(
          map(ids => {
            report.invoice_ids = ids;
            return report;
          })
        );
      }),
      tap(data => this.lastBillingReport$.next(data))
    );
  }

  // TODO: Add report type
  getMostRecentReports(): Observable<any> {
    const url = `${environment.apiV2Url}/reporting/printable?pager.limit=5&state=R`;

    return combineLatest([this.http.get<any>(url), this.accountService.accounts$, this.accountService.households$]).pipe(
      map(([resp, accounts, households]) => {
        const reports = resp.data;

        reports.forEach(report => {
          if (report.account_id) {
            const account = accounts.find(a => a.id === report.account_id);

            if (!account) {
              return;
            }

            report.account_name = account.display_name;
          } else {
            const household = households.find(h => h.id === report.household_id);

            if (!household) {
              return;
            }

            report.household_name = household.name;
          }
        });

        return reports;
      }),
      tap(data => this.latestPDFReports$.next(data))
    );
  }

  getActivityFeedData() {
    return combineLatest([
      this.getTransactions(),
      this.getNewAccounts(),
      this.getBillingReportActivity(),
      this.getMostRecentReports()
    ])
      .pipe(
        map(res => {
          // below is the logic for using the appropriate date fields of each object and using it to sort
          // and organize (e.g. grouping 3 or more new clients into one atlas activity card instead of 3 separate cards)

          // output that will be returned at the end of this function
          const output = [];
          const data = [];

          res.forEach(r => {
            if (r) {
              if (r.length) {
                data.push(...r);
              } else if (r.length === undefined) {
                data.push(r);
              }
            }
          });

          // refactored to above due to errors
          // const data = [...res[0], ...res[1], res[2], ...res[3]];

          data.forEach(item => {
            if (item.object === 'data.custodian.transaction' || item.object === 'data.luca.transfer') {
              item.sort_date = new Date(item.transaction_date);
              item.sort_date.setDate(item.sort_date.getDate() + 1);
            } else if (item.object === 'account_management.account') {
              item.sort_date = new Date(item.inception_date);
              item.sort_date.setDate(item.sort_date.getDate() + 1);
            } else if (item.object === 'billing.report') {
              item.sort_date = new Date(item.created_date);
              item.sort_date.setDate(item.sort_date.getDate() + 1);
            } else if (item.object === 'reporting.printable') {
              item.sort_date = new Date(item.dt_utc);
            }
          });

          data.sort((a, b) => b.sort_date - a.sort_date);

          let groupedByDataType = [];
          let currentType = null;
          let currentDate = null;

          for (let i = 0; i < data.length; i++) {
            const item = data[i];

            if (i === 0) {
              currentType = item.object;
              currentDate = item.sort_date.toLocaleDateString('en-US');
              groupedByDataType.push(item);
            } else {
              if (currentType === item.object && currentType === 'data.luca.transfer') {
                if (groupedByDataType.length) {
                  // no particular reason why first item is used;
                  // simply using an item in the groupedByDataType array
                  // as point of reference
                  if (groupedByDataType[0].security_id !== item.security_id || item.sort_date.toLocaleDateString('en-US') !== currentDate) {
                    output.push(groupedByDataType);
                    groupedByDataType = [];
                    currentDate = item.sort_date.toLocaleDateString('en-US');
                  }
                }
              } else if (currentType !== item.object || item.sort_date.toLocaleDateString('en-US') !== currentDate) {
                if (groupedByDataType.length) {
                  output.push(groupedByDataType);
                  groupedByDataType = [];
                  currentType = item.object;
                  currentDate = item.sort_date.toLocaleDateString('en-US');
                }
              }

              groupedByDataType.push(item);

              if (i === data.length - 1) {
                if (groupedByDataType.length) {
                  output.push(groupedByDataType);
                }
              }
            }
          }

          return output;
        })
      );
  }
}

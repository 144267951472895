import {Injectable} from '@angular/core';
import {ClientManagementService} from '../client-management.service';
import {select, Store} from '@ngrx/store';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {
  CreateClient,
  CreateClientFail,
  CreateClientSuccess,
  LoadClients,
  LoadClientsFail,
  LoadClientsSuccess, RemoveClient, RemoveClientFail, RemoveClients, RemoveClientsSuccess, RemoveClientSuccess,
  UpdateClient,
  UpdateClientFail,
  UpdateClients,
  UpdateClientsFail,
  UpdateClientsSuccess,
  UpdateClientSuccess
} from './client.actions';
import {catchError, map, mergeMap, take, withLatestFrom} from 'rxjs/operators';
import {of} from 'rxjs';
import {getClients, State} from './index';
import {Client} from '../clients.types';
@Injectable()
export class ClientEffects {
  constructor(
    private clientsService: ClientManagementService,
    private actions$: Actions,
    private store: Store<State>
  ) {}

  @Effect()
  loadClients$ = this.actions$.pipe(
    ofType(LoadClients),
    withLatestFrom(this.store.pipe(select(getClients))),
    mergeMap(() => this.clientsService.getClients().pipe(
      map((resp) => LoadClientsSuccess({clients: resp})),
      catchError((err) => of(LoadClientsFail({error: err.error})))
    )),
    take(1),
  );

  @Effect()
  createClient$ = this.actions$.pipe(
    ofType(CreateClient),
    map(action => action.client),
    mergeMap((client: Client) => {
      return this.clientsService.createClient(client).pipe(
        map((createdClient: Client) => CreateClientSuccess({client: createdClient})),
        catchError((err) => {
          return of(CreateClientFail({error: err.error}));
        })
      );
    })
  );

  @Effect()
  updateClient$ = this.actions$.pipe(
    ofType(UpdateClient),
    map(action => action.client),
    mergeMap((client: Client) => {
      return this.clientsService.updateClient(client).pipe(
        map((updatedClient: Client) => UpdateClientSuccess({client: updatedClient})),
        catchError((err) => {
          return of(UpdateClientFail({error: err.error}));
        })
      );
    })
  );

  @Effect()
  updateClients$ = this.actions$.pipe(
    ofType(UpdateClients),
    map(action => action.clients),
    mergeMap((clients: Client[]) => {
      return this.clientsService.updateClients(clients).pipe(
        map((updatedClients: Client[]) => UpdateClientsSuccess({clients: updatedClients})),
        catchError((err) => {
          return of (UpdateClientsFail({error: err.error}));
        })
      );
    })
  );

  @Effect()
  removeClient$ = this.actions$.pipe(
    ofType(RemoveClient),
    map(action => action.client),
    mergeMap((client: Client) => {
      return this.clientsService.removeClient(client).pipe(
        map(() => RemoveClientSuccess({client})),
        catchError((err) => {
          return of(RemoveClientFail({error: err.error}));
        })
      );
    })
  );

  @Effect()
  removeClients$ = this.actions$.pipe(
    ofType(RemoveClients),
    map(action => action.clients),
    mergeMap((clients: Client[]) => {
      return this.clientsService.removeClients(clients).pipe(
        map(() => RemoveClientsSuccess({clients})),
        catchError((err) => {
          return of(RemoveClientFail({error: err.error}));
        })
      );
    })
  );
}

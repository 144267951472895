import {Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {
  CreateSecurityExclusion,
  CreateSecurityExclusionFail,
  CreateSecurityExclusionSuccess,
  LoadSecurityExclusions,
  LoadSecurityExclusionsFail,
  LoadSecurityExclusionsSuccess,
  RemoveSecurityExclusion,
  RemoveSecurityExclusionFail, RemoveSecurityExclusions, RemoveSecurityExclusionsFail, RemoveSecurityExclusionsSuccess,
  RemoveSecurityExclusionSuccess,
  UpdateSecurityExclusion,
  UpdateSecurityExclusionFail,
  UpdateSecurityExclusions,
  UpdateSecurityExclusionsFail,
  UpdateSecurityExclusionsSuccess,
  UpdateSecurityExclusionSuccess,
} from './security-exclusions.actions';
import {catchError, map, mergeMap, take, withLatestFrom} from 'rxjs/operators';
import {of} from 'rxjs';
import {getSecurityExclusions, State} from './index';
import {SecurityExclusion} from '../security-exclusion.type';
import {SecurityExclusionsService} from '../security-exclusions.service';
@Injectable()
export class SecurityExclusionsEffects {
  constructor(
    private securityExclusionsService: SecurityExclusionsService,
    private actions$: Actions,
    private store: Store<State>
  ) {}

  @Effect()
  loadSecurityExclusions$ = this.actions$.pipe(
    ofType(LoadSecurityExclusions),
    withLatestFrom(this.store.pipe(select(getSecurityExclusions))),
    mergeMap(() => this.securityExclusionsService.getSecurityExclusions().pipe(
      map((resp) => LoadSecurityExclusionsSuccess({securityExclusions: resp})),
      catchError((err) => of(LoadSecurityExclusionsFail({error: err.error})))
    )),
    take(1),
  );

  @Effect()
  createSecurityExclusion$ = this.actions$.pipe(
    ofType(CreateSecurityExclusion),
    map(action => action.securityExclusion),
    mergeMap((securityExclusion) => {
      return this.securityExclusionsService.createSecurityExclusion(securityExclusion).pipe(
        map((createdSecurityExclusion) => CreateSecurityExclusionSuccess({securityExclusion: createdSecurityExclusion})),
        catchError((err) => {
          return of(CreateSecurityExclusionFail({error: err.error}));
        })
      );
    })
  );

  @Effect()
  updateSecurityExclusion$ = this.actions$.pipe(
    ofType(UpdateSecurityExclusion),
    map(action => action.securityExclusion),
    mergeMap((securityExclusion: SecurityExclusion) => {
      return this.securityExclusionsService.updateSecurityExclusion(securityExclusion).pipe(
        map((updatedSecurityExclusion: SecurityExclusion) => UpdateSecurityExclusionSuccess({securityExclusion: updatedSecurityExclusion})),
        catchError((err) => {
          return of(UpdateSecurityExclusionFail({error: err.error}));
        })
      );
    })
  );

  @Effect()
  updateSecurityExclusions$ = this.actions$.pipe(
    ofType(UpdateSecurityExclusions),
    map(action => action.securityExclusions),
    mergeMap((securityExclusions: SecurityExclusion[]) => {
      return this.securityExclusionsService.updateSecurityExclusions(securityExclusions).pipe(
        map((updatedSecurityExclusions: SecurityExclusion[]) => UpdateSecurityExclusionsSuccess({securityExclusions: updatedSecurityExclusions})),
        catchError((err) => {
          return of (UpdateSecurityExclusionsFail({error: err.error}));
        })
      );
    })
  );

  @Effect()
  removeSecurityExclusion$ = this.actions$.pipe(
    ofType(RemoveSecurityExclusion),
    map(action => action.securityExclusion),
    mergeMap((securityExclusion: SecurityExclusion) => {
      return this.securityExclusionsService.removeSecurityExclusion(securityExclusion).pipe(
        map(() => RemoveSecurityExclusionSuccess({securityExclusion})),
        catchError((err) => {
          return of(RemoveSecurityExclusionFail({error: err.error}));
        })
      );
    })
  );

  @Effect()
  removeSecurityExclusions$ = this.actions$.pipe(
    ofType(RemoveSecurityExclusions),
    map(action => action.securityExclusions),
    mergeMap((securityExclusions: SecurityExclusion[]) => {
      return this.securityExclusionsService.removeSecurityExclusions(securityExclusions).pipe(
        map(() => RemoveSecurityExclusionsSuccess({securityExclusions})),
        catchError((err) => {
          return of(RemoveSecurityExclusionsFail({error: err.error}));
        })
      );
    })
  );
}

<button *ngIf="!loading"
        mat-button routerLink="/revenue/invoices"
        style="margin-bottom: 10px;">
  <mat-icon>arrow_back</mat-icon> Back to Invoices
</button>

<mat-card *ngIf="!loading">
  <div class="ecommerce-invoice">
    <div class="card">
      <div class="content">
        <!-- Logo + invoice top-line information -->
        <div class="header-container">
          <img class="header-logo" [src]="firm.logo_url" alt="firm logo">

          <div class="header-text-container">
            <div style="color: #636363">
              <h3 class="invoice-header">INVOICE</h3>

              <h5 *ngIf="invoice.direct_billed_period_debit <= 0">THIS IS NOT A BILL</h5>
            </div>
            <br>

            <div style="width: 300px; font-size: 18px; border: 2px solid #5a2ed1; border-radius: 8px; padding: 12px;">
                <div class="date" style="margin-bottom: 8px; ">Invoice Date:
                  <span class="right">
                      {{ invoice.billing_date | date: "MMMM d, yyyy":"UTC" }}
                  </span>
                </div>

                <div class="date">Amount Due:
                  <span class="right">
                      {{ invoice.direct_billed_period_debit | currency }}
                  </span>
                </div>

              <!-- TODO: check the firm.invoice_due_date_option -->
              <div *ngIf="invoice.due_date">
                <div class="date">Due By
                  <span class="right">
                      {{ invoice.due_date | date: "MMMM d, yyyy":"UTC" }}
                  </span>
                </div>
              </div>
            </div>


          </div>
        </div>
        <br/>

        <div *ngIf="isRebateInvoice" style="font-size: 25px">
          <h3>Rebate Invoice</h3>
        </div>
        <div fxLayout="row"
             fxLayout.lt-sm="column"
             gdGap="20px"
             gdColumns.gt-sm="calc(50% - 10px) calc(50% - 10px)"
             style="margin: 30px 0;">

          <div class="address-container">
            From:
            <h4 class="address-item">
              {{ firm.name }}
            </h4>

            <h6 *ngIf="showCustomAddress()">
              <span [innerHtml]="firm.invoice_from"></span>
            </h6>
          </div>

          <div class="address-container">
            To:
            <h4 class="address-item">
              {{ invoice.billing_group_serialized[0].name }}
            </h4>
          </div>
        </div>
      </div>

      <br/>

      <div class="content">
        <h4>Summary</h4>
        <br>

        <dx-data-grid [dataSource]="summaryDataSource"
                      [allowColumnResizing]="false"
                      [showColumnHeaders]="false"
        >
          <dxi-column cellTemplate="textTemplate"
          ></dxi-column>

          <dxi-column cellTemplate="valueTemplate"
                      [alignment]="'right'"
          ></dxi-column>

          <dxi-column [width]="'50%'"></dxi-column>

          <div *dxTemplate="let row of 'textTemplate'">
            {{ row.data }}

            <div *ngIf="row.data === 'Assets Under Management'">
              <small style="color: #636363;">
                As of {{ invoice.snapshot_date | date:"MMMM d, yyyy":"UTC" }}
              </small>
            </div>

            <div *ngIf="row.data === 'Management Fee' && !isRebateInvoice">
              <small style="color: #636363;">
                For the current period (see account details, below)
              </small>
            </div>

            <div *ngIf="row.data === 'Rebate Fee' && isRebateInvoice">
              <small style="color: #636363;">
                For the current period (see account details, below)
              </small>
            </div>

            <div *ngIf="row.data === 'Amount Due'">
              <small *ngIf="!isRebateInvoice" style="color: #636363;">
                {{ invoice.custodian_billed_period_debit | displayAmount }} will be
                directly withdrawn from your custodian
              </small>
              <small *ngIf="isRebateInvoice" style="color: #636363;">
                {{ invoice.custodian_billed_period_debit | displayAmount }} will be
                directly deposited to your custodian
              </small>
            </div>
          </div>

          <div *dxTemplate="let row of 'valueTemplate'">
            <div *ngIf="row.data === 'Assets Under Management'">
              <!--                  TODO: different for iteration 2, 3-->
              {{ invoice.group_value_on_snapshot_date[groupId] | currency }}
            </div>

            <div *ngIf="row.data === 'Billable Balance'">
              {{ invoice.group_df.balance[groupId] | currency }}
            </div>

            <div *ngIf="row.data === 'Management Fee' || row.data === 'Rebate Fee'">
              {{ invoice.period_debit | currency }}
              <br/>
              <small style="color: #636363;">
                {{ invoice.annual_debit | currency }} per annum
              </small>
            </div>

            <div *ngIf="row.data === 'Amount Due'">
              {{ invoice.direct_billed_period_debit | currency }}
            </div>

            <div *ngIf="row.data === 'Effective Rate'">
              {{ periodEffectiveRatePercentage() | number: '0.2-2' }}%

              <div *ngIf="showAnnualEffectiveRates()">
                <small style="color: #636363;">
                  {{ annualEffectiveRatePercentage() | number: '0.2-2' }}% p.a.
                </small>
              </div>
            </div>
          </div>

          <dxo-selection mode="none"></dxo-selection>
        </dx-data-grid>
      </div>

      <br>
      <br>


      <!-- header -->
      <div class="content" *ngIf="showHeader()">
        <div class="row" style="margin-top: 0; margin-bottom: 0;">
          <span [innerHTML]="firm.invoice_header"></span>
        </div>
      </div>


      <!-- account details -->
      <div class="content">
        <h4>Account Details</h4>

        <dx-data-grid [dataSource]="invoice.account_ids"
        >
          <dxi-column caption="Account"
                      dataType="string"
                      [width]="'25%'"
                      cellTemplate="accountTemplate"></dxi-column>

          <dxi-column caption="Fee Structure"
                      dataType="string"
                      *ngIf="showFeeStructures()"
                      cellTemplate="feeStructureTemplate"></dxi-column>

          <dxi-column caption="Valuation"
                      cellTemplate="valuationTemplate"></dxi-column>

          <dxi-column caption="Billable Balance"
                      dataType="number"
                      cellTemplate="billableBalanceTemplate"></dxi-column>

          <dxi-column caption="Period"
                      cellTemplate="periodTemplate"></dxi-column>

          <dxi-column caption="Fee"
                      dataType="number"
                      cellTemplate="feeTemplate"></dxi-column>

          <dxi-column *ngIf="isRebateInvoice"
                      caption="Rebate"
                      dataType="number"
                      cellTemplate="debitTemplate"></dxi-column>

          <dxi-column *ngIf="!isRebateInvoice"
                      caption="Debit"
                      dataType="number"
                      cellTemplate="debitTemplate"></dxi-column>

          <dxi-column caption="Effective Rate"
                      *ngIf="showEffectiveRates()"
                      dataType="number"
                      cellTemplate="effectiveRateTemplate"></dxi-column>

          <div *dxTemplate="let row of 'accountTemplate'">
            {{ getAccountName(row.data) }}
            <br/>
            <small style="color: #636363;" *ngIf="accountsByID[row.data]">
              {{ accountsByID[row.data].number | applyDataMask }}, {{ accountsByID[row.data].custodian | displayCustodian }}
            </small>

            <small style="color: #636363;" *ngIf="invoice.account_df.fee_location[row.data] < 0">
              <br/>
              Charged to group
            </small>

            <small style="color: #636363;" *ngIf="invoice.account_df.fee_location[row.data] > 0">
              <br/>
              Relocated to {{ getAccountNameByFeeLocation(invoice, row.data) }}
            </small>
          </div>

          <div *dxTemplate="let row of 'feeStructureTemplate'">
            <div *ngFor="let fs of assignedFeeStructures(row.data)">
              {{ fs.name }}
            </div>
          </div>

          <div *dxTemplate="let row of 'valuationTemplate'">
            {{ invoice.account_value_on_snapshot_date[row.data] | currency }}
          </div>

          <div *dxTemplate="let row of 'billableBalanceTemplate'">
            {{ invoice.account_df.billable_balance[row.data] | currency }}
          </div>

          <div *dxTemplate="let row of 'periodTemplate'">
            {{ invoice.account_df.period_days[row.data] | number }} days
            <br/>
            <small style="color: #636363;">
              {{ invoice.account_df.billing_period_start[row.data] | date:"M/d/yyyy":"UTC" }}
              to {{ invoice.account_df.billing_period_end[row.data] | date:"M/d/yyyy":"UTC" }}
            </small>
          </div>

<!--          <div *dxTemplate="let row of 'feeTemplate'">-->
<!--            {{ invoice.account_df.account_period_fee[row.data] | currency }}-->
<!--          </div>-->

<!--          <div *dxTemplate="let row of 'debitTemplate'">-->
<!--            {{ invoice.account_df.account_period_debit[row.data] | currency }}-->
<!--          </div>-->

          <div *dxTemplate="let row of 'feeTemplate'">
            <div [ngClass]="getFee(row.data) < 0 ? 'negative-number' : null">
              {{ getFee(row.data) | financialStandard:"currency"}}
            </div>
          </div>

          <div *dxTemplate="let row of 'debitTemplate'">
            <div [ngClass]="getDebit(row.data) < 0 ? 'negative-number' : null">
              {{ getDebit(row.data) | financialStandard:"currency"}}
            </div>
          </div>

          <div *dxTemplate="let row of 'effectiveRateTemplate'">
            {{ invoice.account_df.account_period_effective_rate[row.data] | number: '0.2-2' }}%
            <span *ngIf="showAnnualEffectiveRates()">
            <br/>
            <small style="color: #636363;">
              {{ invoice.account_df.account_annual_effective_rate[row.data] | number: '0.2-2' }}% p.a.
            </small>
            </span>
          </div>

          <dxo-summary [calculateCustomSummary]="calculateSelectedRow">
            <dxi-total-item
              name="Valuation"
              summaryType="custom"
              showInColumn="Valuation"
              displayFormat="{0}">
              <dxo-value-format type="currency" [precision]="2" currency="USD"></dxo-value-format>
            </dxi-total-item>

            <dxi-total-item
              name="BillableBalance"
              summaryType="custom"
              showInColumn="Billable Balance"
              displayFormat="{0}">
              <dxo-value-format type="currency" [precision]="2" currency="USD"></dxo-value-format>
            </dxi-total-item>

            <dxi-total-item
              name="Fees"
              summaryType="custom"
              showInColumn="Fee"
              displayFormat="{0}">
              <dxo-value-format type="currency" [precision]="2" currency="USD"></dxo-value-format>
            </dxi-total-item>

            <dxi-total-item
              name="Debits"
              summaryType="custom"
              showInColumn="Debit"
              displayFormat="{0}">
              <dxo-value-format type="currency" [precision]="2" currency="USD"></dxo-value-format>
            </dxi-total-item>

            <dxi-total-item
              name="Rebate"
              summaryType="custom"
              showInColumn="Rebate"
              displayFormat="{0}">
              <dxo-value-format type="currency" [precision]="2" currency="USD"></dxo-value-format>
            </dxi-total-item>

            <dxi-total-item
              name="EffectiveRate"
              summaryType="custom"
              showInColumn="Effective Rate"
              displayFormat="{0}%">
            </dxi-total-item>
          </dxo-summary>

          <dxo-selection mode="none"></dxo-selection>
        </dx-data-grid>

      </div>

      <br>
      <br>

      <!-- fee structures -->
      <div class="content" *ngIf="showFeeStructures()">
        <h4>Fee Structures</h4>

        <dx-data-grid [dataSource]="invoice.fee_structures_serialized"
                      [allowColumnResizing]="false"
        >

          <dxi-column caption="Name"
                      dataType="string"
                      dataField="name"
                      [allowSorting]="false"
                      [allowFiltering]="false"
          ></dxi-column>

          <dxi-column caption="Periodicity"
                      dataType="string"
                      [allowSorting]="false"
                      [allowFiltering]="false"
                      cellTemplate="periodicityTemplate"
          ></dxi-column>

          <dxi-column caption="Balance Assessment Method"
                      dataType="string"
                      [allowSorting]="false"
                      [allowFiltering]="false"
                      cellTemplate="balanceAssessmentTemplate"
          ></dxi-column>

          <dxi-column caption="Rate"
                      dataType="number"
                      [allowSorting]="false"
                      [allowFiltering]="false"
                      cellTemplate="rateTemplate"
          ></dxi-column>

          <div *dxTemplate="let row of 'periodicityTemplate'">
            {{ row.data.frequency | billingDisplayFrequency }}
            <div>
              Collected in {{ row.data.collection_type | billingDisplayCollectionType | lowercase }}
            </div>
          </div>

          <div *dxTemplate="let row of 'balanceAssessmentTemplate'">
            {{ row.data.balance_type | billingDisplayBalanceType }}
          </div>

          <div *dxTemplate="let row of 'rateTemplate'">
            <div *ngIf="row.data.calculation_type === 'T' || row.data.calculation_type === 'D'">
              <div *ngFor="let tier of row.data.tiers">
                <div style="display: flex; justify-content: space-between; align-items: center;">
                  <div *ngIf="tier.max_balance === null">
                      {{ tier.min_balance | displayAmount }}+
                  </div>
                  <div *ngIf="tier.max_balance !== null">
                      {{ tier.min_balance | displayAmount }} to {{ tier.max_balance | displayAmount }}
                  </div>

                  <div>
                    {{ tier.percentage_rate | number: '0.2-2' }}%
                  </div>
                </div>
              </div>
            </div>

            <p *ngIf="row.data.calculation_type === 'F' || row.data.calculation_type === 'E'">
              Favored. No fee applied.
            </p>

            <p *ngIf="row.data.calculation_type === 'R'">
              {{ row.data.flat_rate | number: '0.2-2' }}% per annum
            </p>

            <p *ngIf="row.data.calculation_type === 'A'">
              {{ row.data.flat_dollar_fee | currency }} per year
            </p>
          </div>

          <dxo-selection mode="none"></dxo-selection>
          <dxo-filter-row [visible]="false"></dxo-filter-row>

        </dx-data-grid>
      </div>

      <br>
      <br>


      <!-- agreement -->
      <div class="content" *ngIf="showAgreement()">
        <div class="row" style="margin-top: 0px; margin-bottom: 0px;">
          <h4>Agreement</h4>
          <br>

          <p class="grey-text" style="color: #636363;">Per your agreement with {{ firm.name }}, the management fee
            {{ invoice.custodian_billed_period_debit | displayAmount }} will be directly
            {{isRebateInvoice ? 'credited to' : 'withdrawn from' }}  your financial institution, as applicable.
            This invoice documents you are responsible for paying
            {{ invoice.direct_billed_period_debit | displayAmount }} directly to your advisor.
          </p>
        </div>
      </div>


      <!-- this is not a bill explanation -->
      <div class="content" *ngIf="nothingDueExplanation() && !isPaymentOwed()">
        <br>
        <br>

        <div class="row" style="margin-top: 0px; margin-bottom: 0px;">
          <h4>This is not a bill</h4>
          <br>

          <p class="grey-text" style="color: #636363;">
            If your custodial account is billed directly for advisory fees, please verify the
            accuracy of the calculation. Custodians will not determine whether fees are accurately calculated.
            Please note that small price differences may exist between custodial data and pricing
            data used in billing calculations. The last price of a security at month-end
            market close is used in calculating management fees.
          </p>
        </div>
      </div>


      <!-- footer -->
      <div class="content" *ngIf="showFooter()">
        <div class="row" style="margin-top: 0px; margin-bottom: 0px;">
          <span [innerHtml]="firm.invoice_footer"></span>
        </div>
      </div>

    </div>
  </div>
</mat-card>

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl} from '@angular/forms';

@Component({
  selector: 'app-atlas-date-picker',
  templateUrl: './atlas-date-picker.component.html',
  styleUrls: ['./atlas-date-picker.component.scss']
})
export class AtlasDatePickerComponent implements OnInit {
  @Input() placeholder: string;
  public max: Date;
  public min: Date;
  selectedDate: string;

  @Output() dateChange = new EventEmitter<string>();

  @Input() control: FormControl;

  @Input() style: any;

  @Input() maxDate: Date | undefined;
  @Input() minDate: Date | undefined;

  constructor() {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);
    this.max = yesterday;
    this.min = new Date(1900, 1, 1);
  }

  ngOnInit() {
    this.setMinAndMaxDate();
  }

  @Input() hint: string;

  @Input()
  get date() {
    return this.selectedDate;
  }

  set date(date: string) {
    this.selectedDate = date;
    this.dateChange.emit(this.selectedDate);
  }

  emitDate(event) {
    const momentDate = event.value;
    this.selectedDate = momentDate.format('YYYY-MM-DD');
    this.dateChange.emit(this.selectedDate);
  }

  setMinAndMaxDate() {
    if (this.maxDate) {
      this.max = this.maxDate;
    }
    if (this.minDate) {
      this.min = this.minDate;
    }
  }
}

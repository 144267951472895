import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FeeStructuresListComponent} from './fee-structures-list/fee-structures-list.component';
import {FeeStructuresCreateEditComponent} from './fee-structures-create-edit/fee-structures-create-edit.component';
import {FeeStructuresResolver} from './fee-structures.resolve';
import {SharedModule} from '../../../shared/shared.module';

@NgModule({
  declarations: [
    FeeStructuresListComponent,
    FeeStructuresCreateEditComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
  ],
  providers: [
    FeeStructuresResolver
  ],
  entryComponents: [
    FeeStructuresCreateEditComponent
  ],
  exports: [
    FeeStructuresListComponent
  ]
})
export class FeeStructuresModule { }

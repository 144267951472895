import {AfterViewInit, ChangeDetectorRef, Component, forwardRef, Inject, OnDestroy, OnInit, Optional} from '@angular/core';
import {MatSelectSearchComponent} from 'ngx-mat-select-search';
import { MatOption } from '@angular/material/core';
import { MatFormField } from '@angular/material/form-field';
import { MatSelect } from '@angular/material/select';
import {ViewportRuler} from '@angular/cdk/overlay';
import {LiveAnnouncer} from '@angular/cdk/a11y';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
  selector: 'app-atlas-select-search',
  templateUrl: './atlas-select-search.component.html',
  styleUrls: ['./atlas-select-search.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AtlasSelectSearchComponent),  // replace name as appropriate
      multi: true
    }
  ]
})
export class AtlasSelectSearchComponent extends MatSelectSearchComponent implements OnInit, OnDestroy, AfterViewInit, ControlValueAccessor {
  noEntriesFoundLabel = 'No entities found';
  placeholderLabel = 'Search';
  constructor(
    @Inject(MatSelect) public matSelect: MatSelect,
    public changeDetectorRef: ChangeDetectorRef,
    private viewportRuler: ViewportRuler,
    @Optional() @Inject(MatOption) public matOption: MatOption = null,
    liveAnnouncer: LiveAnnouncer,
    @Optional() @Inject(MatFormField) public matFormField: MatFormField = null) {
    super(matSelect, changeDetectorRef, viewportRuler, matOption, liveAnnouncer);

  }

}

import {Component, ElementRef, OnInit, AfterViewInit, ViewChild} from '@angular/core';
import {FeeStructuresService} from '../../settings/billing/fee-structures/fee-structures.service';
import {FeeStructure} from '../../billing/billing';
import {AccountService} from '../../accounts/account.service';
import {CurrentBalanceService} from '../../shared/services/current-balance.service';
import {from} from 'rxjs';
import {filter, map, mergeMap} from 'rxjs/operators';

import * as Chart from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { ChartOptions, ChartType } from 'chart.js';
import {BaseChartDirective, Label} from 'ng2-charts';
import {Router} from '@angular/router';
import {ColorsService} from '../../shared/services/colors.service';

@Component({
  selector: 'app-billable-and-non-billable',
  templateUrl: './billable-and-non-billable.component.html',
  styleUrls: ['./billable-and-non-billable.component.scss']
})
export class BillableAndNonBillableComponent implements OnInit, AfterViewInit {
  loading = true;
  showAccounts = false;

  feeStructures: FeeStructure[];
  accounts: any;

  nonBillableFeeStructureIds: number[] = [];
  nonBillableAccountIds: number[] = [];
  billableAccountIds: number[] = [];

  nonBillableBalance = 0;
  billableBalance = 0;

  pieChartDataSource: any;

  selected: 'aum' | 'accounts' = 'aum';

  doughnutChartLabels: Label[] = ['Billable', 'Non-Billable'];
  doughnutChartData = [];
  doughnutChartType: ChartType = 'doughnut';
  chartColors;
  plugins = [ChartDataLabels];

  options: ChartOptions | any = {
    tooltips: {
      custom: tooltip => {
        if (!tooltip) {
          return;
        }
        tooltip.displayColors = false;
      },
      callbacks: {
        title: (tooltipItem) => {
          return this.doughnutChartLabels[tooltipItem[0].index];
        },
        label: (tooltipItem) => {
          const sum = this.doughnutChartData[0] + this.doughnutChartData[1];
          const percent = (this.doughnutChartData[tooltipItem.index] / sum) * 100;

          return `${percent.toFixed(2)}%`;
        }
      },
      shadowOffsetX: 0,
      shadowOffsetY: 1,
      shadowBlur: 3,
      shadowColor: 'rgba(36,40,47,.5)'
    },
    legend: {
      position: 'bottom',
      align: 'center',
      labels: {
        padding: 65
      }
    },
    cutoutPercentage: 65,
    layout: {
      padding: {
        top: 40,
        bottom: 20
      }
    },
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
        offset: 10,
        font: {
          size: 14
        },
        formatter: (value, context) => {
          if (this.selected === 'aum') {
            let val;

            if (value < 1000) {
              val = value.toFixed(2);
            } else if (value < 1000000) {
              val = `${(value / 1000).toFixed(2)}K`;
            } else {
              val = `${(value / 1000000).toFixed(2)}M`;
            }
            return `$${val}`;

          } else if (this.selected === 'accounts') {
            if (value === 1) {
              return `${value} account`;
            } else {
              return `${value} accounts`;
            }
          }
        }
      },
    }
  };

  @ViewChild(BaseChartDirective, { static: true }) baseChartDirective: BaseChartDirective;

  @ViewChild('billableAssetsChart') billableAssetsChart: ElementRef;
  context: CanvasRenderingContext2D;

  constructor(private feeStructureService: FeeStructuresService,
              private accountService: AccountService,
              private currentBalanceService: CurrentBalanceService,
              private colors: ColorsService,
              private router: Router) {
    this.feeStructures = this.feeStructureService.chargeUnits;
  }

  ngOnInit() {}

  setupData() {
    this.nonBillableAccountIds = [];
    this.billableAccountIds = [];
    this.billableBalance = 0;
    this.nonBillableBalance = 0;

    this.feeStructures.forEach((feeStructure) => {
      if (feeStructure.calculation_type === 'F') {
        this.nonBillableFeeStructureIds.push(feeStructure.id);
      }
    });

    this.accounts.forEach(account => {
      const accountBalance = this.currentBalanceService.currentBalanceByAccountID[account.id];

      account.fee_structures_ids.forEach(id => {
        if (this.nonBillableFeeStructureIds.includes(id) && !this.nonBillableAccountIds.includes(account.id)) {
          this.nonBillableAccountIds.push(account.id);

          this.nonBillableBalance += parseFloat(accountBalance ? accountBalance.total_value : '0');

        } else if (!this.billableAccountIds.includes(account.id)) {
          this.billableAccountIds.push(account.id);

          this.billableBalance += parseFloat(accountBalance ? accountBalance.total_value : '0');
        }
      });
    });

    // build data sources
    this.pieChartDataSource = [
      {
        name: 'Billable',
        numAccounts: this.billableAccountIds.length,
        sumBalance: this.billableBalance
      },
      {
        name: 'Non-Billable',
        numAccounts: this.nonBillableAccountIds.length,
        sumBalance: this.nonBillableBalance
      }
    ];
  }

  getPercentageOfBook(balance: number) {
    return balance / this.currentBalanceService.getTotalAUMByAccount();
  }

  ngAfterViewInit() {
    this.accountService.accounts$.pipe(
      filter(accounts => accounts.length > 0),
      mergeMap(accounts => {
        return from(this.currentBalanceService.getBalances()).pipe(
          map(balances => [accounts, balances])
        );
      })
    ).subscribe(([accounts, balances]) => {
      this.accounts = accounts;
      this.setupData();
      this.doughnutChartData = [this.billableBalance, this.nonBillableBalance];
      this.loading = false;
    });

    Chart.defaults.global.defaultFontFamily = 'Fira Sans, "Helvetica Neue", sans-serif';

    this.chartColors = [
      {
        backgroundColor: [
          this.colors.chartPrimaryColor,
          this.colors.chartAccentColor
        ],
        hoverBackgroundColor: [
          this.colors.chartHoverPrimaryColor,
          this.colors.chartHoverAccentColor
        ],
      }
    ];
  }

  toggle(): void {
    if (this.selected === 'aum') {
      this.selected = 'accounts';
      this.doughnutChartData = [this.billableAccountIds.length, this.nonBillableAccountIds.length];
    } else if (this.selected === 'accounts') {
      this.selected = 'aum';
      this.doughnutChartData = [this.billableBalance, this.nonBillableBalance];
    }

    this.baseChartDirective.update();
  }

  isActiveRoute(route: string) {
    return this.router.url.includes(route);
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavbarComponent } from './navbar/navbar.component';
import {SharedModule} from '../shared/shared.module';
import { SearchComponent } from './search/search.component';
import {SidePanelComponent} from './side-panel/side-panel.component';
import {IntegrationsModule} from '../settings/integrations/integrations.module';
import {StoreModule} from '@ngrx/store';
import {reducer} from './state/layout.reducer';

@NgModule({
  declarations: [
    NavbarComponent,
    SearchComponent,
    SidePanelComponent
  ],
  exports: [
    NavbarComponent,
    SearchComponent,
    SidePanelComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    IntegrationsModule,
    StoreModule.forFeature('layout', reducer),
  ],
  entryComponents: [
  ]
})
export class LayoutModule { }

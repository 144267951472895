import { Injectable } from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import _mapKeys from 'lodash-es/mapKeys';
import {from, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {CurrentBalance} from '../type_classes/luca/current-balance';
import {AccountService} from '../../accounts/account.service';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class CurrentBalanceService {
  endpoint: string;
  lucaEndpoint: string;

  state: any;

  currentBalances: any;
  currentBalanceByAccountID: any;
  currentBalancesByHouseholdId: any;
  currentBalanceByHouseholdID: any;

  currentLucaBalances: any;
  currentLucaBalanceByAccountID: any;
  currentLucaBalancesByHouseholdId: any;
  currentLucaBalanceByHouseholdID: any;

  constructor(
    private http: HttpClient,
    private accountService: AccountService
  ) {
    this.lucaEndpoint = `${environment.apiV2Url}/data/luca`;

    this.currentBalanceByAccountID = {};
    this.currentBalancesByHouseholdId = {};
    this.currentBalanceByHouseholdID = {};

    this.currentLucaBalanceByAccountID = {};
    this.currentLucaBalancesByHouseholdId = {};
    this.currentLucaBalanceByHouseholdID = {};
  }

  // Last day available in the balance table (last time data was ingested)
  get lastDate$(): Observable<string> {
    return from(this.getBalances())
      .pipe(
        map(balances => {
          const dates = balances.map(b => moment(b.as_of_date).utc());

          return moment.max(dates).utc().format('YYYY-MM-DD');
        })
        // map(balances => '2019-11-27')
      );
  }

  get lucaLastDate$(): Observable<string> {
    return from(this.getLucaBalances())
      .pipe(
        map(balances => balances[0] && balances[0].as_of_date)
        // map(balances => '2020-06-10')
      );
  }

  getBalances(): Promise<CurrentBalance[]> {
    const lucaUrl = `${this.lucaEndpoint}/balance/current`;

    if (this.currentBalances && this.currentBalances.length) {
      return Promise.resolve(this.currentBalances);
    }

    const promises = [
      this.http.get(lucaUrl).toPromise<any>()
    ];

    return Promise.all(promises).then(([lucaBalances]) => {
      // lucaBalances = lucaBalances;

      this.currentBalances = [];

      const lucaAccountIDs = this.accountService.lucaMigratedAccountIDs;
      const lucaHouseholdIDs = this.accountService.lucaMigratedHouseholdIDs;

      // Hacky for now, need to make sure these fields match up with what the fiserv balance object uses
      if (lucaBalances) {
        lucaBalances.forEach(balance => {
          balance.total_value = balance.cash_value + balance.security_holdings_value;
          this.currentBalances.push(balance);
        });
      }

      this.setupData(this.currentBalances);

      return this.currentBalances;
    });
  }

  getLucaBalances(): Promise<any[]> {
    const url = `${this.lucaEndpoint}/balance`;

    if (this.currentLucaBalances) {
      return Promise.resolve(this.currentLucaBalances);
    }

    return this.http.get(url).toPromise<any>().then((resp: { data: any }) => {
      this.currentLucaBalances = resp.data;
      this.setupLucaData(this.currentLucaBalances);
      return resp.data;
    });
  }

  setupData(currentBalances: any) {
    const accountBalances = currentBalances.filter((balance) => balance.account_id !== null);
    const householdBalances = currentBalances.filter((balance) => balance.household_id !== null);

    accountBalances.forEach((balance) => {
      this.currentBalanceByAccountID[balance.account_id] = balance;
    });

    householdBalances.forEach((balance) => {
      this.currentBalancesByHouseholdId[balance.household_id] = balance;
    });

    Object.assign(this.currentBalanceByHouseholdID, _mapKeys(householdBalances, 'household_id'));
  }

  setupLucaData(currentLucaBalances: any) {
    const accountBalances = currentLucaBalances.filter((balance) => balance.account !== null);
    const householdBalances = currentLucaBalances.filter((balance) => balance.household !== null);

    accountBalances.forEach((balance) => {
      this.currentLucaBalanceByAccountID[balance.account_id] = balance;
    });

    householdBalances.forEach((balance) => {
      this.currentLucaBalancesByHouseholdId[balance.household_id] = balance;
    });

    Object.assign(this.currentLucaBalanceByHouseholdID, _mapKeys(householdBalances, 'household_id'));
  }

  getTotalAUMByHousehold() {
    let sum = 0;

    this.currentBalances.forEach((balance) => {
      if (balance.household_id) {
        sum += parseFloat(balance.total_value);
      }
    });

    return sum;
  }

  getTotalLucaAUMByHousehold() {
    let sum = 0;

    this.currentLucaBalances.forEach((balance) => {
      if (balance.household_id) {
        sum += parseFloat(balance.total_value);
      }
    });

    return sum;
  }

  getTotalAUMByAccount() {
    let sum = 0;

    this.currentBalances.forEach((balance) => {
      if (balance.account_id) {
        sum += parseFloat(balance.total_value);
      }
    });

    return sum;
  }

  getTotalLucaAUMByAccount() {
    let sum = 0;

    this.currentLucaBalances.forEach((balance) => {
      if (balance.account_id) {
        sum += parseFloat(balance.total_value);
      }
    });

    return sum;
  }
}

import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {FirmService} from '../../firm/firm.service';
import {Firm} from '../../firm/firm';
import {CognitoService} from '../../shared/services/auth/cognito.service';
import {ActivatedRoute, Router} from '@angular/router';
import {UserProfileService} from '../../shared/services/auth/user-profile.service';
import {LayoutService} from '../layout.service';
import {SearchService} from '../search/search.service';
import {WealthboxService} from '../../settings/integrations/wealthbox/wealthbox.service';
import {SidePanelService} from '../side-panel/side-panel.service';
import {MatSidenav} from '@angular/material/sidenav';
import {UxAccessService} from '../../shared/services/ux-access.service';
import {WealthboxSettingsService} from '../../settings/integrations/wealthbox/wealthbox-settings/wealthbox-settings.service';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit, AfterViewInit {

  firm: Firm;
  currentSection = 'ATLAS_ACTIVITY';

  @ViewChild('sidenav') sidenav: MatSidenav;

  isFirmUser = this.userProfileService.isFirmUser;
  isEndClient = this.userProfileService.isEndClientUser;
  showShortcutModal = false;
  private searchState: boolean;

  userFirstName: string;

  sideBarOpened = false;

  constructor(private firmService: FirmService,
              private cognitoService: CognitoService,
              private router: Router,
              private route: ActivatedRoute,
              private userProfileService: UserProfileService,
              private searchService: SearchService,
              private navigationService: LayoutService,
              private wealthboxSettingsService: WealthboxSettingsService,
              private wealthboxService: WealthboxService,
              private sidePanelService: SidePanelService,
              public uxAccessService: UxAccessService) {
    this.searchState = navigationService.isSearchLayerVisible;
  }

  ngOnInit() {
    this.firm = this.firmService.firm;
    this.userFirstName = this.userProfileService.userProfile.first_name;
  }

  ngAfterViewInit() {}

  isActiveRoute(route: string) {

    if (route === '/dashboard') {
      return this.router.url === '/dashboard';
    }

    if (route === '/insights/dashboard') {
      return this.router.url === '/insights/dashboard';
    }

    return !this.router.url.includes('settings') && this.router.url.includes(route);
  }
  getLogoUrl() {
    return this.firmService.getLogoUrl();
  }

  showFirmLogo() {
    return this.firm.show_firm_logo;
  }

  showBridgeLogo() {
    return this.firm.show_bridge_logo;
  }

  hasLogo() {
    return this.firm && this.firm.logo_url && this.firm.logo_url.length > 0;
  }

  openSearch() {
    this.searchService.displaySearch();
    // this.navigationService.toggleSearchLayer(true);
  }

  closeSearch() {
    this.searchService.closeSearch();
    // this.navigationService.toggleSearchLayer(false);
  }

  logout() {
    this.cognitoService.logout();
    (window as any).location = '';
  }

  wealthboxEnabled(): Observable<boolean> {
    return this.wealthboxSettingsService.configuration$.pipe(
      map((config) => {
        return config ? !!config : false;
      })
    );
  }

  togglePanel(e) {
    if (e.target.value === 'WEALTHBOX') {
      this.wealthboxEnabled().toPromise().then(enabled => {
        if (!enabled) {
          this.routeToIntegrationsPage();
        }
      });

      this.currentSection = e.target.value;
      this.sidePanelService.togglePanel(this.currentSection);
    }
  }

  routeToIntegrationsPage() {
    this.router.navigateByUrl('/settings/integrations');
  }

  // hacky, temporary way of limiting view to only firms associated with UXW
  // TODO: refactor - we'll probably need to add a boolean field to firms to determine whether to show the investments page
  showInvestmentsPage() {
    // @ts-ignore
    return this.firm.id === 455 || this.firm.parent_firm_id === 455 || this.firm.id === 39 || this.firm.parent_firm_id === 551 || this.firm.id === 404 || this.firm.id === 607 || this.firm.id === 645 || this.firm.id === 646 || this.firm.id === 781 || this.firm.id === 782 || this.firm.id === 792 || this.firm.id === 804 || this.firm.id === 801 || this.firm.id === 814 || this.firm.parent_firm_id === 549 || this.firm.id === 805 || this.firm.parent_firm_id === 781;
  }
}

import { Injectable } from '@angular/core';
import {UserProfileService} from './auth/user-profile.service';

@Injectable({
  providedIn: 'root'
})
export class UxAccessService {
  // TODO: Remove this service
  // This service is specifically for blocking specific UX users from viewing certain parts of the application.
  // Once the new data model is in place and permissions has been overhauled, this functionality will be given to advisors.

  /***
   Permission Set 1:
     Disable Access on Dashboard page for
     - Assets Under Management
     - Firm Wide Target vs Actual Allocation
     - Firm Wide Reporting (leave on if we can figure out how to not make it grey out other accounts)
     - Top Holdings
     - Revenue Analytics
     Turn off Revenue and Insights Tab
     Turn off Settings tab
   Permission Set 2:
     Everything that is denied in Permission Set 1, but also denying:
     - On Investment Models, turn off Models tab
     - On Clients, turn off Actions > Bulk Assign > “Investment Model” and “Fee Structure” option
     - On Accounts Detail page, turn off “Required Cash”, “Investment Model” and “Unsupervised Assets”, and “Fee Structure”
   Permission Set 3:
    Permission Set 2 but Dashboard and Settings are accessible
   */

  permissionSetOneUserIDs = [
    6601,
    6602,
    6605,
    6453,
    6454,
    6455,
    6456,
    6457,
    6459,
    6460,
    6597,
    6463,
    6466,
    6599,
    6444,
    6591,
    6479,
    6592,
    6593,
    6480,
    6594,
    6595,
    6481,
    6596,
    6482,
    6600,
    6632,
    6674,
    6675,
    6676,
    6677,
    6854,
    6887,
    7639,
    8812,
    9573,
    9623,
    9622,
    9665, // NewSquare
  ];

  permissionSetTwoUserIDs = [
    6444,
    6591,
    6479,
    6592,
    6593,
    6480,
    6594,
    6595,
    6481,
    6596,
    6482,
    6600,
    6674,
    6675,
    6676,
    6854,
    6887,
    8812,
    9573,
    9623,
    9622,
    9665, // NewSquare
  ];

  permissionSetThreeUserIDs = [
    6479,
    6480,
    6481,
    6482,
    6600,
  ];

  // Currently only for ausdal firms
  // same as no permission set (all functionality) but turn off Revenue, remove revenue analytics from dashboard, on account details page turn off fee structure
  permissionSetFourUserIDs = [
    6590,
    6447,
    6443,
    6445,
    6448,
    6449,
    6450,
    8367,
    6451,
    6446,
    6617,
    8126,
    8811,
    8317
  ];

  viewOnlyUserIDs = [
    6479,
    6480,
    6481,
    6593,
    6482,
    6594,
    6600,
    6592,
    6595,
    6596,
    6674,
    6675,
    6676,
  ];

  permissionSetForFileExport = [
    5206,
    5886,
    8888,
  ];

  permissionSetForUpdateSecurityAllocation = [
    5206,
    5886,
    8888,
  ];

  permissionSetForStrategyUpdate = [
    5206,
    5208,
    8888,
    5886
  ];


  constructor(private profileService: UserProfileService) {

  }

  get canAccessPermissionSetOne(): boolean {
    return !this.permissionSetOneUserIDs.includes(this.profileService.userProfile.id);
  }

  get canAccessPermissionSetTwo(): boolean {
    return !this.permissionSetTwoUserIDs.includes(this.profileService.userProfile.id);
  }

  get canAccessPermissionSetThree(): boolean {
    return !this.permissionSetThreeUserIDs.includes(this.profileService.userProfile.id);
  }

  get canAccessPermissionSetFour(): boolean {
    return !this.permissionSetFourUserIDs.includes(this.profileService.userProfile.id);
  }

  get isViewOnly(): boolean {
    return this.viewOnlyUserIDs.includes(this.profileService.userProfile.id);
  }

  get canAccessPermissionForFileExport(): boolean {
    return this.permissionSetForFileExport.includes(this.profileService.userProfile.id);
  }

  get canAccessPermissionForUpdatingSecurityAllocation(): boolean {
    return this.permissionSetForUpdateSecurityAllocation.includes(this.profileService.userProfile.id);
  }

  get canAccessPermissionForStrategyUpdate(): boolean {
    return this.permissionSetForStrategyUpdate.includes(this.profileService.userProfile.id);
  }
}

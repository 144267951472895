import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {CognitoService} from './shared/services/auth/cognito.service';
import {FirmService} from './firm/firm.service';
import {UserProfileService} from './shared/services/auth/user-profile.service';
import {UserProfile} from './shared/services/auth/user-profile';
import {JobsService} from './shared/services/jobs.service';
import {AppLoadingService} from './layout/app-loading.service';
import {environment} from '../environments/environment';
import {Firm} from './firm/firm';
import {VersionCheckService} from './shared/services/version-check.service';
import {combineLatest, Observable, of, Subscription} from 'rxjs';
import {ActivityService} from './settings/integrations/atlas/activity.service';
import 'chartjs-plugin-style';
import {filter, switchMap, take, tap} from 'rxjs/operators';
import {ColorsService} from './shared/services/colors.service';
import {NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, RouterEvent} from '@angular/router';
import {ChurnZeroService} from './shared/services/churn-zero.service';
import * as Sentry from '@sentry/angular';
import {AppInitializerService} from './shared/services/app-initializer.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {

  isAppLoading$: Observable<boolean>;
  authSubscription: Subscription;

  resolvingRoute = true;

  constructor(private cognitoService: CognitoService,
              private firmService: FirmService,
              private userProfileService: UserProfileService,
              private jobsService: JobsService,
              private appLoadingService: AppLoadingService,
              private versionCheckService: VersionCheckService,
              private activityService: ActivityService,
              private colors: ColorsService,
              private router: Router,
              private churnZeroService: ChurnZeroService,
              private appInitializerService: AppInitializerService) {
      appInitializerService.init();
  }

  ngOnInit() {
    this.trackResolvingRoutes();

    this.versionCheckService.initVersionCheck();
  }

  ngAfterViewInit(): void {
    // Listen for authentication, once logged in, data loading triggers
    this.authSubscription = this.cognitoService.authenticatedObservable
      .pipe(
        switchMap(authenticated => this.userProfileService.fetchUserProfile()),
        switchMap((userProfile: UserProfile) => {
          return combineLatest([
            of(userProfile),
            this.firmService.fetchFirmByID(userProfile.firm_id)
          ]);
        }),
        take(1)
      ).subscribe(([profile, firm]) => {
        if (environment.production) {

          Sentry.setUser({email: profile.email});
          this.initFullStory(profile, firm);
          this.initIntercom(profile, firm);
          this.initPendo(this.userProfileService.userProfile, firm);
        }
        // TODO: Move this somewhere else
        if (profile.is_firm_user) {
          this.jobsService.initJobService();
          this.activityService.getBillingReportActivity().subscribe();
        }
      });
  }

  ngOnDestroy() {
    this.authSubscription.unsubscribe();
  }

  initIntercom(profile: UserProfile, firm: Firm) {
  // Initialize Intercom
    if (environment.intercomKey !== '') {
      (window as any).Intercom('boot', {
        app_id: environment.intercomKey,
        email: profile.email,
        name: profile.full_name,
        is_firm_user: profile.is_firm_user,
        company: {
          id: firm.id,
          name: firm.name
        },
        parent_firm_id: firm.parent_firm_id,
        firm_id: firm.id,
      });
    }
  }

  initChurnZero(profile: UserProfile, firm: Firm) {
    if (profile.is_client_user) {
      return;
    }
    this.churnZeroService.init(profile, firm);
  }

  initPendo(profile: UserProfile, firm: Firm) {
    // Initializing Pendo, pendo tracks user activity in production, the code in this function is provided by pendo
    (function (apiKey) {
      (function (p, e, n, d, o) {
        var v, w, x, y, z;
        o = p[d] = p[d] || {};
        o._q = [];
        v = ['initialize', 'identify', 'updateOptions', 'pageLoad'];
        for (w = 0, x = v.length; w < x; ++w) (function (m) {
          o[m] = o[m] || function () {
            o._q[m === v[0] ? 'unshift' : 'push']([m].concat([].slice.call(arguments, 0)));
          };
        })(v[w]);
        y = e.createElement(n);
        y.async = !0;
        y.src = 'https://cdn.pendo.io/agent/static/' + apiKey + '/pendo.js';
        z = e.getElementsByTagName(n)[0];
        z.parentNode.insertBefore(y, z);
      })(window, document, 'script', 'pendo');

      const email = profile.email;
      const userId = profile.id;

      const visitorId = `${firm.short_name}:${email}:${userId}`;

      // firm name: id
      const accountId = `${firm.short_name}:${firm.id}`;

      (window as any).pendo.initialize({

        visitor: {
          id: visitorId,
          email: profile.email,
          role: profile.role_name,
          creationDate: profile.verified_dt_utc,
          fullName: profile.full_name,

          user_name: profile.username,
          date_joined: profile.verified_dt_utc,
          first_name: profile.first_name,
          last_name: profile.last_name,

          is_client_user: profile.is_client_user,
          is_demo_user: profile.is_demo_user,
          is_firm_user: profile.is_firm_user,
          is_owner: profile.is_owner
        },

        account: {
          id: accountId,
          firmCode: firm.short_name,
          firmName: firm.name,
          firmId: firm.id,
          firmRelationshipCode: firm.relationship_code
        }
      });
    })(environment.pendoApiKey);
  }

  initRaven(profile: UserProfile) {

  }

  initFullStory(profile: UserProfile, firm: Firm) {
// This is an example script - don't forget to change it!
    (window as any).FS.identify(`${profile.email}`, {
      displayName: profile.full_name,
      email: profile.email,
      firm_id: profile.firm_id,
      firm_name: firm.name,
      is_firm_user: profile.is_firm_user,
      is_client_user: profile.is_client_user,

      // TODO: Add your own custom user variables here, details at
      // https://help.fullstory.com/hc/en-us/articles/360020623294
    });
  }

  trackResolvingRoutes() {
    this.router.events.pipe(
      filter((routerEvent: RouterEvent) => {
        return routerEvent.id === 1 || routerEvent.url === '/cp/main';
      })
    ).subscribe((routerEvent: RouterEvent) => {
      if (routerEvent instanceof NavigationStart) {
        this.resolvingRoute = true;
      }

      if (routerEvent instanceof NavigationEnd ||
        routerEvent instanceof NavigationError ||
        routerEvent instanceof NavigationCancel
      ) {
        this.resolvingRoute = false;
      }
    });
  }
}



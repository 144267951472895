import {createEntityAdapter, EntityState} from '@ngrx/entity';
import {SecurityExclusion} from '../security-exclusion.type';
import {CallState} from '../../../../state/types';
import {EntityAdapter} from '@ngrx/entity/src/models';
import {InitialCallState} from '../../../../state/helpings/initial-call-state';
import {Action, createReducer, on} from '@ngrx/store';
import {
  CreateSecurityExclusion,
  CreateSecurityExclusionFail,
  CreateSecurityExclusionSuccess,
  LoadSecurityExclusions,
  LoadSecurityExclusionsFail,
  LoadSecurityExclusionsSuccess,
  RemoveSecurityExclusion,
  RemoveSecurityExclusionFail, RemoveSecurityExclusions, RemoveSecurityExclusionsFail, RemoveSecurityExclusionsSuccess,
  RemoveSecurityExclusionSuccess,
  UpdateSecurityExclusion,
  UpdateSecurityExclusionFail,
  UpdateSecurityExclusions,
  UpdateSecurityExclusionsFail,
  UpdateSecurityExclusionsSuccess,
  UpdateSecurityExclusionSuccess,
} from './security-exclusions.actions';

export interface SecurityExclusionState extends EntityState<SecurityExclusion> {
  callState: CallState;
  createCallState: CallState;
  updateCallState: CallState;
  updateManyCallState: CallState;
  removeCallState: CallState;
  removeMultipleCallState: CallState;
}

export const adapter: EntityAdapter<SecurityExclusion> = createEntityAdapter<SecurityExclusion>();

const initialState: SecurityExclusionState = adapter.getInitialState({
  callState: {...new InitialCallState()},
  createCallState: {...new InitialCallState()},
  updateCallState: {...new InitialCallState()},
  updateManyCallState: {...new InitialCallState()},
  removeCallState: {...new InitialCallState()},
  removeMultipleCallState: {...new InitialCallState()},
});

export function reducer(state: SecurityExclusionState | undefined, action: Action) {
  const reportPackageReducer = createReducer(
    initialState,
    on(LoadSecurityExclusions, (s) => {
      const callState = {...s.callState, loading: true};
      return {...s, callState};
    }),

    on(LoadSecurityExclusionsSuccess, (s, {securityExclusions}) => {
      const callState = {loading: false, loaded: true, error: null};
      return {...adapter.setAll(securityExclusions, s), callState};
    }),

    on(LoadSecurityExclusionsFail, (s, {error}) => {
      const callState = {loading: false, loaded: false, error};
      return {...state, callState};
    }),

    on(CreateSecurityExclusion, (s) => {
      const createCallState = {...s.createCallState, loading: true};
      return {...s, createCallState};
    }),

    on(CreateSecurityExclusionSuccess, (s, {securityExclusion}) => {
      const createCallState = {loading: false};
      return {...adapter.addOne(securityExclusion, s), createCallState};
    }),

    on(CreateSecurityExclusionFail, (s) => {
      const createCallState = {loading: false};
      return {...s, createCallState};
    }),

    on(UpdateSecurityExclusion, (s) => {
      const updateManyCallState = {...s.updateManyCallState, loading: true};
      return {...s, updateManyCallState};
    }),

    on(UpdateSecurityExclusionSuccess, (s, {securityExclusion}) => {
      const updateCallState = {loading: false};
      return {...adapter.upsertOne(securityExclusion, s), updateCallState};
    }),

    on(UpdateSecurityExclusionFail, (s) => {
      const updateCallState = {loading: false};
      return {...s, updateCallState};
    }),

    on(UpdateSecurityExclusions, (s) => {
      const updateManyCallState = {...s.updateManyCallState, loading: true};
      return {...s, updateManyCallState};
    }),

    on(UpdateSecurityExclusionsSuccess, (s, {securityExclusions}) => {
      const updateManyCallState = {loading: false};
      const ids = securityExclusions.map(f => f.id);
      return {...adapter.upsertMany(securityExclusions, s), updateManyCallState};
    }),

    on(UpdateSecurityExclusionsFail, (s) => {
      const updateManyCallState = {loading: false};
      return {...s, updateManyCallState};
    }),

    on(RemoveSecurityExclusion, (s) => {
      const removeCallState = {...s.removeCallState, loading: true};
      return {...s, removeCallState};
    }),

    on(RemoveSecurityExclusionSuccess, (s, {securityExclusion}) => {
      const removeCallState = {loading: false};
      return {...adapter.removeOne(securityExclusion.id, s), removeCallState};
    }),

    on(RemoveSecurityExclusionFail, (s) => {
      const removeCallState = {loading: false};
      return {...s, removeCallState};
    }),

    on(RemoveSecurityExclusions, (s) => {
      const removeMultipleCallState = {...s.removeMultipleCallState, loading: true};
      return {...s, removeMultipleCallState};
    }),

    on(RemoveSecurityExclusionsSuccess, (s, {securityExclusions}) => {
      const removeMultipleCallState = {loading: false};
      const ids = securityExclusions.map(f => f.id);
      return {...adapter.removeMany(ids, s), removeMultipleCallState};
    }),

    on(RemoveSecurityExclusionSuccess, (s) => {
      const removeMultipleCallState = {loading: false};
      return {...s, removeMultipleCallState};
    }),
  );

  return reportPackageReducer(state, action);
}

const {
  selectAll,
} = adapter.getSelectors();

// select the array of users
export const selectAllSecurityExclusions = selectAll;

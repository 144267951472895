import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {Invoice} from './invoice';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import * as moment from 'moment';
import {Router} from '@angular/router';
import {UtilsService} from '../../shared/services/utils.service';
import {from, Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class InvoiceService {
  private endpoint = `${environment.apiV2Url}/billing/invoices`;

  public invoices: Invoice[] = [];
  constructor(private http: HttpClient,
              private router: Router,
              private utilsService: UtilsService) {}

  getInvoices(): Promise<Invoice[]> {
    const pageSize = 5000;


    // if (this.invoices.length > 0) {
    //   const page = Math.trunc(this.invoices.length / pageSize) + 1;
    //
    //   return this.utilsService.stepByStepLoading(this.endpoint, pageSize, page).then((resp: Invoice[]) => {
    //     const invoices = resp.map((i) => {
    //       i.date = moment(i.billing_date);
    //       return i;
    //     });
    //
    //     this.invoices.push(...invoices);
    //
    //     return this.invoices;
    //   });
    // }

    return this.utilsService.stepByStepLoading(this.endpoint, pageSize)
      .then((resp: Invoice[]) => {
        this.invoices = resp.map((i) => {
          i.date = moment(i.billing_date);
          return i;
        });

        return this.invoices;
      });
  }

  getInvoice(id: number): Promise<Invoice> {
    const url = `${this.endpoint}/${id}`;

    return this.http.get(url).toPromise()
      .then((resp: Invoice) => resp);
  }

  /**
   * Downloads a PDF invoice
   * @param invoiceObj the full invoice object
   */
  downloadPDFInvoice(invoiceObj: Invoice) {
    const url = `${this.endpoint}/download/${invoiceObj.id}`;

    let header = new HttpHeaders();
    header = header.append('Content-Type', 'application/octet-stream');
    header = header.append('Accept', 'application/octet-stream');

    return this.http.post(url, {}, {responseType: 'arraybuffer', headers:  header}).subscribe((data) => {
      this.downloadPDF(invoiceObj, data);
    });
  }

  private downloadPDF(invoiceObj: Invoice, data: any) {
    const blob = new Blob([data], {type: 'application/pdf'});
    const pdf = window.URL.createObjectURL(blob);

    // Trigger a browser download
    const a: any = document.createElement('a');
    a.style = 'display: none';
    a.href = pdf;
    a.download = `Invoice-${invoiceObj.billing_date}-${invoiceObj.billing_group_serialized[0].name.replace('.', '')}`;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(pdf);
  }

  getInvoicesFromBillingReport(billingReportID: number): Observable<Invoice[]> {
    // todo invoices
    const url = `${this.endpoint}?billing_report_id=${billingReportID}`;

    return from(this.utilsService.stepByStepLoading(url,  100));
  }

  getInvoiceIDsFromBillingReportID(billingReportID: number): Observable<number[]> {
    // todo invoices
    const url = `${this.endpoint}?billing_report_id=${billingReportID}`;

    return from(this.utilsService.stepByStepLoading(url, 100)).pipe(
      map((data: Invoice[]) => {
        return data.map(invoice => invoice.id);
      })
    );
  }

  getMostRecentInvoice(): Observable<Invoice> {
    const url = `${this.endpoint}?pager.limit=1`;

    return this.http.get(url).pipe(
      map((resp: {data: Invoice}) => {
        return resp.data[0];
      })
    );
  }
}

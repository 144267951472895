import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {COMMA, ENTER} from '@angular/cdk/keycodes';

// If you already have a list of items, this can be used as multiselect with a search
@Component({
  selector: 'app-atlas-multiselect',
  templateUrl: './atlas-multiselect.component.html',
  styleUrls: ['./atlas-multiselect.component.scss']
})
export class AtlasMultiselectComponent implements OnInit {
  @Input() valueField: string;
  @Input() displayField: string;
  @Input() data: any[];
  @Input() placeholder: string;
  @Input() hint: string;
  @Input() smallerText: boolean;
  @Input() disabled: boolean;
  @Input() control: FormControl;

  selected: any;
  selectedItems = [];

  searchControl = new FormControl();
  searchValue: string;

  filteredItems$: Observable<any>;

  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  @Input() get value() {
    return this.selected;
  }

  set value(value) {
    this.selected = value;
    this.setSelectedItems();
    this.control.setValue(this.selected);
    this.valueChange.emit(this.selected);
  }

  @Output() valueChange = new EventEmitter<any>();

  constructor() {
    if (!this.control) {
      this.control = new FormControl();
    }
  }

  ngOnInit() {
    this.filteredItems$ = this.control.valueChanges.pipe(
      startWith(''),
      map((searchValue: string) => {
        if (typeof searchValue === 'string') {
          this.searchValue = searchValue.toLowerCase();
        }

        return this.data.filter((item: any) => {
          return item[this.displayField].toLowerCase().includes(this.searchValue);
        });
      })
    );
  }

  shortenText(text: string): string {
    if (text.length > 25) {
      return `${text.slice(0, 25)}...`;
    }

    return text;
  }

  optionClicked(event, item) {
    event.stopPropagation();
    this.toggleSelection(item);
  }

  isChecked(item): boolean {
    if (this.selected) {
      this.selectedItems = this.data.filter(d => this.selected.includes(d[this.valueField]));
      return this.selected.includes(item[this.valueField]);
    }
  }

  toggleSelection = (item) => {
    if (this.selected) {
      if (!this.selected.includes(item[this.valueField])) {
        this.selected.push(item[this.valueField]);
      } else {
        const index = this.selected.findIndex(value => value === item[this.valueField]);
        this.selected.splice(index, 1);
      }
    }

    this.control.setValue(this.selected);
    this.valueChange.emit(this.selected);
  }

  private setSelectedItems() {
    if (this.selected) {
      if (this.data) {
        this.selectedItems = this.data.filter(d => this.selected.includes(d[this.valueField]));
      }
    }

  }

  displaySelected = (value) => {
    if (this.selected) {
      return this.selected.length + ' selected';
    }
  }

  remove(item) {
    const index = this.selected.findIndex(value => value === item[this.valueField]);
    this.selected.splice(index, 1);
    this.setSelectedItems();

    this.control.setValue(this.selected);
    this.valueChange.emit(this.selected);
  }

  removeAll() {
    this.selected = [];
    this.setSelectedItems();

    this.control.setValue(this.selected);
    this.valueChange.emit(this.selected);
  }

}

import {Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {Actions, Effect, ofType} from '@ngrx/effects';
import {
  CreateAlertCondition,
  CreateAlertConditionFail,
  CreateAlertConditionSuccess,
  LoadAlertConditions,
  LoadAlertConditionsFail,
  LoadAlertConditionsSuccess,
  RemoveAlertCondition,
  RemoveAlertConditionFail, RemoveAlertConditions, RemoveAlertConditionsFail, RemoveAlertConditionsSuccess,
  RemoveAlertConditionSuccess,
  UpdateAlertCondition,
  UpdateAlertConditionFail,
  UpdateAlertConditions,
  UpdateAlertConditionsFail,
  UpdateAlertConditionsSuccess,
  UpdateAlertConditionSuccess,
} from './alert-conditions.actions';
import {catchError, map, mergeMap, take, withLatestFrom} from 'rxjs/operators';
import {of} from 'rxjs';
import {getAlertConditions, State} from './index';
import {AlertConditionSet} from '../alert-condition.type';
import {AlertConditionsService} from '../alert-conditions.service';
@Injectable()
export class AlertConditionsEffects {
  constructor(
    private alertConditionsService: AlertConditionsService,
    private actions$: Actions,
    private store: Store<State>
  ) {}

  @Effect()
  loadAlertConditions$ = this.actions$.pipe(
    ofType(LoadAlertConditions),
    withLatestFrom(this.store.pipe(select(getAlertConditions))),
    mergeMap(() => this.alertConditionsService.getAlertConditions().pipe(
      map((resp) => LoadAlertConditionsSuccess({alertConditions: resp})),
      catchError((err) => of(LoadAlertConditionsFail({error: err.error})))
    )),
    take(1),
  );

  @Effect()
  createAlertCondition$ = this.actions$.pipe(
    ofType(CreateAlertCondition),
    map(action => action.alertCondition),
    mergeMap((alertCondition) => {
      return this.alertConditionsService.createAlertCondition(alertCondition).pipe(
        map((createdAlertCondition) => CreateAlertConditionSuccess({alertCondition: createdAlertCondition})),
        catchError((err) => {
          return of(CreateAlertConditionFail({error: err.error}));
        })
      );
    })
  );

  @Effect()
  updateAlertCondition$ = this.actions$.pipe(
    ofType(UpdateAlertCondition),
    map(action => action.alertCondition),
    mergeMap((alertCondition: AlertConditionSet) => {
      return this.alertConditionsService.updateAlertCondition(alertCondition).pipe(
        map((updatedAlertCondition: AlertConditionSet) => UpdateAlertConditionSuccess({alertCondition: updatedAlertCondition})),
        catchError((err) => {
          return of(UpdateAlertConditionFail({error: err.error}));
        })
      );
    })
  );

  @Effect()
  updateAlertConditions$ = this.actions$.pipe(
    ofType(UpdateAlertConditions),
    map(action => action.alertConditions),
    mergeMap((alertConditions: AlertConditionSet[]) => {
      return this.alertConditionsService.updateAlertConditions(alertConditions).pipe(
        map((updatedAlertConditions: AlertConditionSet[]) => UpdateAlertConditionsSuccess({alertConditions: updatedAlertConditions})),
        catchError((err) => {
          return of (UpdateAlertConditionsFail({error: err.error}));
        })
      );
    })
  );

  @Effect()
  removeAlertCondition$ = this.actions$.pipe(
    ofType(RemoveAlertCondition),
    map(action => action.alertCondition),
    mergeMap((alertCondition: AlertConditionSet) => {
      return this.alertConditionsService.removeAlertCondition(alertCondition).pipe(
        map(() => RemoveAlertConditionSuccess({alertCondition})),
        catchError((err) => {
          return of(RemoveAlertConditionFail({error: err.error}));
        })
      );
    })
  );

  @Effect()
  removeAlertConditions$ = this.actions$.pipe(
    ofType(RemoveAlertConditions),
    map(action => action.alertConditions),
    mergeMap((alertConditions: AlertConditionSet[]) => {
      return this.alertConditionsService.removeAlertConditions(alertConditions).pipe(
        map(() => RemoveAlertConditionsSuccess({alertConditions})),
        catchError((err) => {
          return of(RemoveAlertConditionsFail({error: err.error}));
        })
      );
    })
  );
}

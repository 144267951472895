import {createEntityAdapter, EntityState} from '@ngrx/entity';
import {InsightsParameter} from '../parameter.type';
import {CallState} from '../../../../state/types';
import {EntityAdapter} from '@ngrx/entity/src/models';
import {InitialCallState} from '../../../../state/helpings/initial-call-state';
import {Action, createReducer, on} from '@ngrx/store';
import {
  LoadParameters,
  LoadParametersFail,
  LoadParametersSuccess,
  UpdateParameter,
  UpdateParameterFail,
  UpdateParameters,
  UpdateParametersFail,
  UpdateParametersSuccess,
  UpdateParameterSuccess,
} from './parameters.actions';

export interface ParameterState extends EntityState<InsightsParameter> {
  callState: CallState;
  createCallState: CallState;
  updateCallState: CallState;
  updateManyCallState: CallState;
  removeCallState: CallState;
  removeMultipleCallState: CallState;
}

export const adapter: EntityAdapter<InsightsParameter> = createEntityAdapter<InsightsParameter>();

const initialState: ParameterState = adapter.getInitialState({
  callState: {...new InitialCallState()},
  createCallState: {...new InitialCallState()},
  updateCallState: {...new InitialCallState()},
  updateManyCallState: {...new InitialCallState()},
  removeCallState: {...new InitialCallState()},
  removeMultipleCallState: {...new InitialCallState()},
});

export function reducer(state: ParameterState | undefined, action: Action) {
  const parameterReducer = createReducer(
    initialState,
    on(LoadParameters, (s) => {
      const callState = {...s.callState, loading: true};
      return {...s, callState};
    }),

    on(LoadParametersSuccess, (s, {parameters}) => {
      const callState = {loading: false, loaded: true, error: null};
      return {...adapter.setAll(parameters, s), callState};
    }),

    on(LoadParametersFail, (s, {error}) => {
      const callState = {loading: false, loaded: false, error};
      return {...state, callState};
    }),

    on(UpdateParameter, (s) => {
      const updateManyCallState = {...s.updateManyCallState, loading: true};
      return {...s, updateManyCallState};
    }),

    on(UpdateParameterSuccess, (s, {parameter}) => {
      const updateCallState = {loading: false};
      return {...adapter.upsertOne(parameter, s), updateCallState};
    }),

    on(UpdateParameterFail, (s) => {
      const updateCallState = {loading: false};
      return {...s, updateCallState};
    }),

    on(UpdateParameters, (s) => {
      const updateManyCallState = {...s.updateManyCallState, loading: true};
      return {...s, updateManyCallState};
    }),

    on(UpdateParametersSuccess, (s, {parameters}) => {
      const updateManyCallState = {loading: false};
      const ids = parameters.map(f => f.id);
      return {...adapter.upsertMany(parameters, s), updateManyCallState};
    }),

    on(UpdateParametersFail, (s) => {
      const updateManyCallState = {loading: false};
      return {...s, updateManyCallState};
    }),
  );

  return parameterReducer(state, action);
}

const {
  selectAll,
} = adapter.getSelectors();

// select the array of users
export const selectAllParameters = selectAll;

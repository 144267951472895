<input matInput class="mat-select-search-input mat-select-search-hidden"/>

<div
    #innerSelectSearch
    class="mat-select-search-inner mat-typography mat-datepicker-content mat-tab-header"
    [ngClass]="{'mat-select-search-inner-multiple': matSelect.multiple, 'mat-select-search-inner-toggle-all': _isToggleAllCheckboxVisible() }">

  <mat-checkbox *ngIf="_isToggleAllCheckboxVisible()"
                [color]="matFormField?.color"
                class="mat-select-search-toggle-all-checkbox"
                [checked]="toggleAllCheckboxChecked"
                [indeterminate]="toggleAllCheckboxIndeterminate"
                (change)="_emitSelectAllBooleanToParent($event.checked)"
  ></mat-checkbox>

  <div class="atlas-input" [class.has-suffix]="true">
    <input matInput
           class="mat-select-search-input atlas-input-element"
           autocomplete="off"
           [type]="type"
           [value]="value"
           #searchSelectInput
           (keydown)="_handleKeydown($event)"
           (keyup)="_handleKeyup($event)"
           (input)="onInputChange($event.target.value)"
           (blur)="onBlur($event.target.value)"
           [placeholder]="placeholderLabel"
           [attr.aria-label]="ariaLabel"
    />
    <div class="atlas-input-suffix" *ngIf="!!value && !searching"  (click)="_reset(true)">
      <span class="fa fa-times-circle icon-gray"></span>
    </div>
  </div>


  <mat-spinner *ngIf="searching"
               class="mat-select-search-spinner"
               diameter="16"></mat-spinner>

  <ng-content select=".mat-select-search-custom-header-content"></ng-content>

</div>

<div *ngIf="_noEntriesFound()"
     class="mat-select-search-no-entries-found">
  {{noEntriesFoundLabel}}
</div>

import {APP_INITIALIZER, ErrorHandler, NgModule} from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import { UrlHandler } from './app-routing.module';
import {Router, UrlHandlingStrategy} from '@angular/router';
import { AppComponent } from './app.component';

import { UtilsService } from './shared/services/utils.service';
import { FirmService } from './firm/firm.service';
import { HttpInterceptorService } from './shared/services/http-interceptor.service';
import { SettingsService } from './shared/services/settings.service';
import { CognitoService } from './shared/services/auth/cognito.service';
import {SharedModule} from './shared/shared.module';
import { MainComponent } from './main/main.component';
// import {InlineEditorModule} from '@qontu/ngx-inline-editor';
import {
  AccountsHouseholdsResolver, AccountsResolver,
  BenchmarksResolver, BillingGroupsResolver,
  FirmResolver, HouseholdsResolver,
  JobsResolver,
  ProfilesResolver,
  USDResolver
} from './app.resolve';
import { SpinnerComponent } from './ui/spinner/spinner.component';
import {LayoutModule} from './layout/layout.module';
import {LoginModule} from './login/login.module';
import {ServicePagesModule} from './service-pages/service-pages.module';
import { SuccessNotificationComponent } from './ui/success-notification/success-notification.component';
import { ErrorNotificationComponent } from './ui/error-notification/error-notification.component';
import {FormsModule} from '@angular/forms';
import {IntegrationsModule} from './settings/integrations/integrations.module';
import {BrowserModule} from '@angular/platform-browser';
import {FeeStructuresResolver} from './settings/billing/fee-structures/fee-structures.resolve';
import { ProductboardComponent } from './productboard/productboard.component';

// NgRx
import {RootStateModule} from './state/root-state.module';
import {SecuritiesModule} from './securities/securities.module';
import {NgxTippyModule, NgxTippyService} from 'ngx-tippy-wrapper';
import { WarningDialogComponent } from './ui/warning-dialog/warning-dialog.component';
import {JobsModule} from './jobs/jobs.module';
import {UnsubscribeModule} from './unsubscribe/unsubscribe.module';
import * as Sentry from '@sentry/angular';

@NgModule({
    declarations: [
        AppComponent,
        MainComponent,
        SpinnerComponent,
        SuccessNotificationComponent,
        ErrorNotificationComponent,
        ProductboardComponent,
        WarningDialogComponent,
    ],
    imports: [
        AppRoutingModule,
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        LayoutModule,
        SharedModule,
        // InlineEditorModule,
        BrowserAnimationsModule,
        LoginModule,
        ServicePagesModule,
        FormsModule,
        IntegrationsModule,
        FormsModule,
        RootStateModule,
        SecuritiesModule,
        NgxTippyModule,
        JobsModule,
        UnsubscribeModule,
    ],
    providers: [
      {
        provide: ErrorHandler,
        useValue: Sentry.createErrorHandler({
          showDialog: false,
        }),
      },
      {
        provide: Sentry.TraceService,
        deps: [Router],
      },
      {
        provide: APP_INITIALIZER,
        useFactory: () => () => {},
        deps: [Sentry.TraceService],
        multi: true,
      },
      {provide: UrlHandlingStrategy, useClass: UrlHandler},
      {provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorService, multi: true},
      UtilsService,
      FirmService,
      SettingsService,
      CognitoService,
      USDResolver,
      JobsResolver,
      FirmResolver,
      FeeStructuresResolver,
      BenchmarksResolver,
      ProfilesResolver,
      AccountsHouseholdsResolver,
      HouseholdsResolver,
      AccountsResolver,
      BillingGroupsResolver,
      NgxTippyService
    ],
    entryComponents: [
      AppComponent,
      SpinnerComponent,
      SuccessNotificationComponent,
      ErrorNotificationComponent,
      WarningDialogComponent
    ]
})
export class AppModule {
  ngDoBootstrap(app) {
    app.bootstrap(AppComponent);
  }
}

<div *ngIf="loading">
  <app-material-spinner></app-material-spinner>
</div>

<div *ngIf="!loading">

  <dx-data-grid #dataGrid
                id="gridContainer"
                [dataSource]="invoices"
                width="100%"
                appHighlightRow
                [allowColumnResizing]="true"
                [allowColumnReordering]="true"
                [columnAutoWidth]="true"
                style="max-height: 500px;"
  >
    <dxi-column
      caption="Invoice Date"
      dataType="date"
      dataField="billing_date"
      cellTemplate="invoiceDetailTemplate"
    >
    </dxi-column>

    <!--      <dxi-column-->
    <!--        caption="Details"-->
    <!--        dataType="string"-->
    <!--        dataField="id"-->
    <!--        width="80"-->
    <!--        [allowFiltering]="false"-->
    <!--        [allowSorting]="false"-->
    <!--        [allowGrouping]="false"-->
    <!--        cellTemplate="actionsLinkTemplate">-->
    <!--      </dxi-column>-->

    <dxi-column
      caption="Valuation"
      dataType="number"
      dataField="total_balance">
      <dxo-format [precision]="0" type="currency"></dxo-format>
    </dxi-column>
    <dxi-column
      caption="Fees"
      dataType="number"
      dataField="period_debit">
      <dxo-format [precision]="2" type="currency"></dxo-format>
    </dxi-column>
    <dxi-column
      caption="Custodian Billed"
      dataType="number"
      dataField="custodian_billed_period_debit">
      <dxo-format [precision]="2" type="currency"></dxo-format>
    </dxi-column>
    <dxi-column
      caption="Directly Billed"
      dataType="number"
      dataField="direct_billed_period_debit">
      <dxo-format [precision]="2" type="currency"></dxo-format>
    </dxi-column>

    <dxi-column
      caption="Actions"
      dataType="string"
      dataField="id"
      [allowFiltering]="false"
      [allowSorting]="false"
      [allowGrouping]="false"
      cellTemplate="downloadLinkTemplate">
    </dxi-column>

    <dxo-pager [visible]="false"></dxo-pager>
    <dxo-scrolling mode="virtual"></dxo-scrolling>

    <dxo-export fileName="invoices_list"></dxo-export>
    <dxo-state-storing [enabled]="true" storageKey="invoices"></dxo-state-storing>
    <dxo-selection
      selectAllMode="allPages"
      showCheckBoxesMode="always"
      mode="multiple"
    ></dxo-selection>

    <div *dxTemplate="let row of 'invoiceDetailTemplate'">
      {{ row.data.billing_date | date:"shortDate":"UTC" }}
    </div>

    <div *dxTemplate="let cell of 'downloadLinkTemplate'">
      <button mat-icon-button color="primary" *ngIf="!cell.row.data.error_message" [routerLink]="" (click)="downloadPDFInvoice(cell.data)">
        <i class="fas fa-arrow-to-bottom datagrid-icon"></i>
      </button>

      <span *ngIf="cell.row.data.error_message" class="badge error" matTooltip="Contact client success support@bridgeft.com">Error</span>
    </div>

    <dxo-selection mode="none"></dxo-selection>
  </dx-data-grid>
</div>


import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SendClientEmailComponent } from './send-client-email/send-client-email.component';
import {SharedModule} from '../shared/shared.module';
import { ClientInboxLandingComponent } from './client-inbox-landing/client-inbox-landing.component';



@NgModule({
  declarations: [SendClientEmailComponent, ClientInboxLandingComponent],
  imports: [
    SharedModule,
    CommonModule
  ],
  exports: [
    SendClientEmailComponent
  ],
  entryComponents: [
    SendClientEmailComponent
  ]
})
export class
ClientInboxModule { }

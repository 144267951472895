import {FeeStructure} from '../../../../billing/billing';
import * as FeeStructureActions from './fee-structure.actions';
import {createEntityAdapter, EntityState} from '@ngrx/entity';
import {EntityAdapter} from '@ngrx/entity/src/models';
import {Action, createReducer, on} from '@ngrx/store';
import {CallState} from '../../../../state/types';
import {InitialCallState} from '../../../../state/helpings/initial-call-state';

export interface FeeStructureState  extends EntityState<FeeStructure> {
  callState: CallState;
  updateCallState: CallState;
  removeCallState: CallState;
  removeMultipleCallState: CallState;
}
export const adapter: EntityAdapter<FeeStructure> = createEntityAdapter<FeeStructure>();

const initialState: FeeStructureState = adapter.getInitialState({
  callState: {...new InitialCallState()},
  updateCallState: {...new InitialCallState()},
  removeCallState: {...new InitialCallState()},
  removeMultipleCallState: {...new InitialCallState()}
});

const feeStructureReducer = createReducer(
  initialState,
  on(FeeStructureActions.Load, (state) => {
    const callState = {...state.callState, loading: true};
    return {...state, callState};
  }),
  on(FeeStructureActions.LoadSuccess, (state, {feeStructures}) => {
    const callState = {loading: false, loaded: true, error: null};
    return {...adapter.addAll(feeStructures, state), callState};
  }),
  on(FeeStructureActions.LoadFail, (state, {error}) => {
    const callState = {loading: false, loaded: false, error};
    return {...state, callState};
  }),
  on(FeeStructureActions.UpdateFeeStructure, (state) => {
    const updateCallState = {...state.updateCallState, loading: true};
    return {...state, updateCallState};
  }),
  on(FeeStructureActions.UpdateFeeStructureSuccess, (state, {feeStructure}) => {
    const updateCallState = {loading: false};
    return {...adapter.upsertOne(feeStructure, state), updateCallState};
  }),
  on(FeeStructureActions.UpdateFeeStructureFail, (state) => {
    const updateCallState = {loading: false};
    return {...state, updateCallState};
  }),
  on(FeeStructureActions.RemoveFeeStructure, (state) => {
    const removeCallState = {...state.removeCallState, loading: true};
    return {...state, removeCallState};
  }),
  on(FeeStructureActions.RemoveFeeStructureSuccess, (state, {feeStructure}) => {
    const removeCallState = {loading: false};
    return {...adapter.removeOne(feeStructure.id, state), removeCallState};
  }),
  on(FeeStructureActions.RemoveFeeStructureFail, (state) => {
    const removeCallState = {loading: false};
    return {...state, removeCallState};
  }),
  on(FeeStructureActions.RemoveMultipleFeeStructure, (state) => {
    const removeMultipleCallState = {...state.removeMultipleCallState, loading: true};
    return {...state, removeMultipleCallState};
  }),
  on(FeeStructureActions.RemoveMultipleFeeStructureSuccess, (state, {feeStructures}) => {
    const removeMultipleCallState = {loading: false};
    const ids = feeStructures.map(f => f.id);
    return {...adapter.removeMany(ids, state), removeMultipleCallState};
  }),
  on(FeeStructureActions.RemoveMultipleFeeStructureFail, (state) => {
    const removeMultipleCallState = {loading: false};
    return {...state, removeMultipleCallState};
  }),
);

export function reducer(state: FeeStructureState | undefined, action: Action) {
  return feeStructureReducer(state, action);
}

// get the selectors
const {
  selectAll,
} = adapter.getSelectors();

// select the array of users
export const selectAllFeeStructures = selectAll;

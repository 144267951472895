import {Household} from '../household';
import * as HouseholdActions from './household.actions';
import {createEntityAdapter, EntityState} from '@ngrx/entity';
import {EntityAdapter} from '@ngrx/entity/src/models';
import {Action, createReducer, on} from '@ngrx/store';
import {CallState} from '../../state/types';
import {InitialCallState} from '../../state/helpings/initial-call-state';

export interface HouseholdState extends EntityState<Household> {
  callState: CallState;
  createCallState: CallState;
  updateCallState: CallState;
  updateManyCallState: CallState;
  removeCallState: CallState;
  removeMultipleCallState: CallState;
}
export const adapter: EntityAdapter<Household> = createEntityAdapter<Household>();

const initialState: HouseholdState = adapter.getInitialState({
  callState: {...new InitialCallState()},
  createCallState: {...new InitialCallState()},
  updateCallState: {...new InitialCallState()},
  updateManyCallState: {...new InitialCallState()},
  removeCallState: { ... new InitialCallState() },
  removeMultipleCallState: { ...new InitialCallState() }
});

const householdReducer = createReducer(
  initialState,
  on(HouseholdActions.Load, (state) => {
    const callState = {...state.callState, loading: true};
    return {...state, callState};
  }),

  on(HouseholdActions.LoadSuccess, (state, {households}) => {
    const callState = {loading: false, loaded: true, error: null};
    return {...adapter.addAll(households, state), callState};
  }),

  on(HouseholdActions.LoadFail, (state, {error}) => {
    const callState = {loading: false, loaded: false, error};
    return {...state, callState};
  }),

  on(HouseholdActions.CreateHousehold, (state) => {
    const createCallState = {...state.createCallState, loading: true};
    return {...state, createCallState};
  }),

  on(HouseholdActions.CreateHouseholdSuccess, (state, {household}) => {
    const createCallState = {loading: false};
    return {...adapter.addOne(household, state), createCallState};
  }),
  on(HouseholdActions.CreateHouseholdFail, (state) => {
    const createCallState = {loading: false};
    return {...state, createCallState};
  }),

  on(HouseholdActions.UpdateHousehold, (state) => {
    const updateCallState = {...state.updateCallState, loading: true};
    return {...state, updateCallState};
  }),

  on(HouseholdActions.UpdateHouseholdSuccess, (state, {household}) => {
    const updateCallState = {loading: false};
    return {...adapter.upsertOne(household, state), updateCallState};
  }),

  on(HouseholdActions.UpdateHouseholdFail, (state) => {
    const updateCallState = {loading: false};
    return {...state, updateCallState};
  }),

  on(HouseholdActions.UpdateHouseholds, (state) => {
    const updateManyCallState = {...state.updateManyCallState, loading: true};
    return {...state, updateManyCallState};
  }),

  on(HouseholdActions.UpdateHouseholdsSuccess, (state, {households}) => {
    const updateManyCallState = {loading: false};
    const ids = households.map(f => f.id);
    return {...adapter.upsertMany(households, state), updateManyCallState};
  }),

  on(HouseholdActions.UpdateHouseholdsFail, (state) => {
    const updateManyCallState = {loading: false};
    return {...state, updateManyCallState};
  }),

  on(HouseholdActions.RemoveHousehold, (state) => {
    const removeCallState = {...state.removeCallState, loading: true};
    return {...state, removeCallState};
  }),

  on(HouseholdActions.RemoveHouseholdSuccess, (state, {household}) => {
    const removeCallState = {loading: false};
    return {...adapter.removeOne(household.id, state), removeCallState};
  }),
  on(HouseholdActions.RemoveHouseholdFail, (state) => {
    const removeCallState = {loading: false};
    return {...state, removeCallState};
  }),
  on(HouseholdActions.RemoveMultipleHouseholds, (state) => {
    const removeMultipleCallState = {...state.removeMultipleCallState, loading: true};
    return {...state, removeMultipleCallState};
  }),
  on(HouseholdActions.RemoveMultipleHouseholdsSuccess, (state, {households}) => {
    const removeMultipleCallState = {loading: false};
    const ids = households.map(h => h.id);
    return {...adapter.removeMany(ids, state), removeMultipleCallState};
  }),
  on(HouseholdActions.RemoveMultipleHouseholdsFail, (state) => {
    const removeMultipleCallState = {loading: false};
    return {...state, removeMultipleCallState};
  }),
);

export function reducer(state: HouseholdState | undefined, action: Action) {
  return householdReducer(state, action);
}

// get the selectors
const {
  selectAll,
} = adapter.getSelectors();

// select the array of users
export const selectAllHouseholds = selectAll;


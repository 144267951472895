import {createEntityAdapter, EntityState} from '@ngrx/entity';
import {Client} from '../clients.types';
import {CallState} from '../../state/types';
import {EntityAdapter} from '@ngrx/entity/src/models';
import {InitialCallState} from '../../state/helpings/initial-call-state';
import {Action, createReducer, on} from '@ngrx/store';
import {
  CreateClient, CreateClientFail, CreateClientSuccess,
  LoadClients,
  LoadClientsFail,
  LoadClientsSuccess,
  RemoveClient,
  RemoveClientFail,
  RemoveClients,
  RemoveClientsSuccess,
  RemoveClientSuccess,
  UpdateClient,
  UpdateClientFail,
  UpdateClients,
  UpdateClientsFail,
  UpdateClientsSuccess,
  UpdateClientSuccess
} from './client.actions';

export interface ClientState extends EntityState<Client> {
  callState: CallState;
  createCallState: CallState;
  updateCallState: CallState;
  updateManyCallState: CallState;
  removeCallState: CallState;
  removeMultipleCallState: CallState;
}

export const adapter: EntityAdapter<Client> = createEntityAdapter<Client>();

const initialState: ClientState = adapter.getInitialState({
  callState: {...new InitialCallState()},
  createCallState: {...new InitialCallState()},
  updateCallState: {...new InitialCallState()},
  updateManyCallState: {...new InitialCallState()},
  removeCallState: {...new InitialCallState()},
  removeMultipleCallState: {...new InitialCallState()},
});

export function reducer(state: ClientState | undefined, action: Action) {
  const clientReducer = createReducer(
    initialState,
    on(LoadClients, (s) => {
      const callState = {...s.callState, loading: true};
      return {...s, callState};
    }),

    on(LoadClientsSuccess, (s, {clients}) => {
      const callState = {loading: false, loaded: true, error: null};
      return {...adapter.setAll(clients, s), callState};
    }),

    on(LoadClientsFail, (s, {error}) => {
      const callState = {loading: false, loaded: false, error};
      return {...state, callState};
    }),

    on(CreateClient, (s) => {
      const createCallState = {...s.createCallState, loading: true};
      return {...s, createCallState};
    }),

    on(CreateClientSuccess, (s, {client}) => {
      const createCallState = {loading: false};
      return {...adapter.addOne(client, s), createCallState};
    }),

    on(CreateClientFail, (s) => {
      const createCallState = {loading: false};
      return {...s, createCallState};
    }),

    on(UpdateClient, (s) => {
      const updateManyCallState = {...s.updateManyCallState, loading: true};
      return {...s, updateManyCallState};
    }),

    on(UpdateClientSuccess, (s, {client}) => {
      const updateCallState = {loading: false};
      return {...adapter.upsertOne(client, s), updateCallState};
    }),

    on(UpdateClientFail, (s) => {
      const updateCallState = {loading: false};
      return {...s, updateCallState};
    }),

    on(UpdateClients, (s) => {
      const updateManyCallState = {...s.updateManyCallState, loading: true};
      return {...s, updateManyCallState};
    }),

    on(UpdateClientsSuccess, (s, {clients}) => {
      const updateManyCallState = {loading: false};
      const ids = clients.map(f => f.id);
      return {...adapter.upsertMany(clients, s), updateManyCallState};
    }),

    on(UpdateClientsFail, (s) => {
      const updateManyCallState = {loading: false};
      return {...s, updateManyCallState};
    }),

    on(RemoveClient, (s) => {
      const removeCallState = {...s.removeCallState, loading: true};
      return {...s, removeCallState};
    }),

    on(RemoveClientSuccess, (s, {client}) => {
      const removeCallState = {loading: false};
      return {...adapter.removeOne(client.id, s), removeCallState};
    }),

    on(RemoveClientFail, (s) => {
      const removeCallState = {loading: false};
      return {...s, removeCallState};
    }),

    on(RemoveClients, (s) => {
      const removeMultipleCallState = {...s.removeMultipleCallState, loading: true};
      return {...s, removeMultipleCallState};
    }),

    on(RemoveClientsSuccess, (s, {clients}) => {
      const removeMultipleCallState = {loading: false};
      const ids = clients.map(f => f.id);
      return {...adapter.removeMany(ids, s), removeMultipleCallState};
    }),

    on(RemoveClientSuccess, (s) => {
      const removeMultipleCallState = {loading: false};
      return {...s, removeMultipleCallState};
    }),
  );

  return clientReducer(state, action);
}

const {
  selectAll,
} = adapter.getSelectors();

// select the array of users
export const selectAllClients = selectAll;

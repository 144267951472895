import {Component, ElementRef, HostBinding, Input, OnDestroy, OnInit, Optional, Self} from '@angular/core';
import { MatFormFieldControl } from '@angular/material/form-field';
import {Subject} from 'rxjs';
import {ControlValueAccessor, FormControl, NgControl} from '@angular/forms';
import {coerceBooleanProperty} from '@angular/cdk/coercion';
import {FocusMonitor} from '@angular/cdk/a11y';

@Component({
  selector: 'app-atlas-input',
  templateUrl: './atlas-input.component.html',
  providers: [
    {provide: MatFormFieldControl, useExisting: AtlasInputComponent},
  ]
})
export class AtlasInputComponent implements MatFormFieldControl<any>, ControlValueAccessor, OnDestroy, OnInit {
  get errorState(): boolean {
    return this.ngControl.invalid && (this.inputCtrl.touched || this.inputCtrl.dirty);
  }

  @Input()
  get placeholder() {
    return this.placeholderLocal || '';
  }
  set placeholder(plh) {
    this.placeholderLocal = plh;
    this.stateChanges.next();
  }

  @Input()
  get required() {
    return this.requiredLocal;
  }
  set required(req) {
    this.requiredLocal = coerceBooleanProperty(req);
    this.stateChanges.next();
  }

  @Input()
  get disabled(): boolean { return this.disabledLocal; }
  set disabled(value: boolean) {
    this.disabledLocal = coerceBooleanProperty(value);
    if (value) {
      this.inputCtrl.disable();
    } else {
      this.inputCtrl.enable();
    }

    this.stateChanges.next();
  }

  @Input()
  get value(): string | null {
    return this.inputCtrl.value;
  }

  constructor(
    private elementRef: ElementRef,
    @Optional() @Self() public ngControl: NgControl,
    private fm: FocusMonitor, private elRef: ElementRef<HTMLElement>
  ) {
    if (this.ngControl != null) {
      // @ts-ignore
      this.ngControl.valueAccessor = this;
    }

    this.inputCtrl = new FormControl('');

    this.inputCtrl.valueChanges.subscribe((value) => {
      this.stateChanges.next();
      this.onChange(value);
    });

    fm.monitor(elRef.nativeElement, true).subscribe(origin => {
      this.focused = !!origin;
      this.stateChanges.next();
    });
  }

  get empty() {
    return false;
  }

  get hasSuffix() {
    return !!this.inputCtrl.value || this.errorState;
  }

  static nextId = 0;
  inputCtrl: FormControl;
  private onTouched: () => void;

  @HostBinding('attr.aria-describedby') describedBy = '';
  private requiredLocal = false;
  private disabledLocal = false;

  private placeholderLocal: string;
  focused = false;
  stateChanges = new Subject<void>();
  shouldLabelFloat = false;


  @HostBinding() id = `example-tel-input-${AtlasInputComponent.nextId++}`;

  ngOnInit(): void {
    this.onTouched();
    this.stateChanges.next();
  }
  onChange = (value: string) => {};

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  writeValue(value: string): void {
    this.inputCtrl.setValue(value);
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ');
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onContainerClick(event: MouseEvent) {
    if ((event.target as Element).tagName.toLowerCase() !== 'input') {
      this.elementRef.nativeElement.querySelector('input').focus();
    }
  }

  clearInput() {
    this.inputCtrl.setValue('');
  }

  ngOnDestroy() {
    this.stateChanges.complete();
  }

  onBlur() {
    this.stateChanges.next();
  }
}

import { NgModule } from '@angular/core';
import {PipesModule} from './pipes/pipes.module';
import { HelpPopupComponent } from './components/help-popup/help-popup.component';
import { DevExtremeModule} from '../devextreme/dev-extreme.module';
import { DataGridLinkTemplateComponent } from './components/data-grid-link-template/data-grid-link-template.component';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import { DataGridActionsBarComponent } from './components/data-grid-actions-bar/data-grid-actions-bar.component';
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';
import { FillHeightDirective } from './directives/fill-height.directive';
import { PermissionsComponent } from './components/permissions/permissions.component';
import { RouteActiveDirective } from './directives/route-active.directive';
import { SelectOnClickDirective } from './directives/select-on-click.directive';
import { MaterialElevationDirective } from './directives/material-elevation.directive';
import { HighlightRowDirective } from './dx-helpers/highlight-row.directive';
import {ApiKeyEditorComponent} from './components/api-key-editor/api-key-editor.component';
import {ReactiveFormsModule} from '@angular/forms';
import {MaterialModule} from '../material/material.module';
import { MaterialSpinnerComponent } from '../ui/material-spinner/material-spinner.component';
import {AtlasUiModule} from '../atlas-ui/atlas-ui.module';
import {FlexLayoutModule} from '@angular/flex-layout';
import { ConfirmationComponent } from './components/notification/confirmation/confirmation.component';
import { ColumnChooserComponent } from './components/column-chooser/column-chooser.component';
import { DataGridSearchComponent } from './components/data-grid-search/data-grid-search.component';
import {NgxTippyModule} from 'ngx-tippy-wrapper';
import {DropzoneModule} from 'ngx-dropzone-wrapper';
import {NgxCurrencyModule} from 'ngx-currency';

@NgModule({
  declarations: [
    HelpPopupComponent,
    DataGridLinkTemplateComponent,
    DataGridActionsBarComponent,
    BreadcrumbsComponent,
    FillHeightDirective,
    PermissionsComponent,
    RouteActiveDirective,
    SelectOnClickDirective,
    MaterialElevationDirective,
    HighlightRowDirective,
    ApiKeyEditorComponent,
    MaterialSpinnerComponent,
    ConfirmationComponent,
    ColumnChooserComponent,
    DataGridSearchComponent,
  ],
  imports: [
    PipesModule,
    DevExtremeModule,
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    MaterialModule,
    AtlasUiModule,
    FlexLayoutModule,
    NgxTippyModule,
    DropzoneModule,
    NgxCurrencyModule
  ],
    exports: [
      PipesModule,
      HelpPopupComponent,
      DataGridLinkTemplateComponent,
      DataGridActionsBarComponent,
      BreadcrumbsComponent,
      FillHeightDirective,
      PermissionsComponent,
      RouteActiveDirective,
      SelectOnClickDirective,
      MaterialElevationDirective,
      HighlightRowDirective,
      ApiKeyEditorComponent,
      MaterialModule,
      MaterialSpinnerComponent,
      AtlasUiModule,
      FlexLayoutModule,
      ColumnChooserComponent,
      DataGridSearchComponent,
      DevExtremeModule,
      NgxTippyModule,
      RouterModule,
      DropzoneModule,
      NgxCurrencyModule
    ],
  entryComponents: [
    ConfirmationComponent
  ]
})
export class SharedModule { }

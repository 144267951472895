export class Household {
  // tslint:disable:variable-name
  benchmarks_ids: number[];
  close_date: string;
  entity_id: string;
  firm_ids: number[];
  id: number;
  inception_date: string;
  is_account: boolean;
  is_household: boolean;
  firm_id: number;
  name: string;
  object = 'account_management.household';
  opening_date?: string;
  short_name: string;
  status: string;

  first_funded_date?: string;
}

import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {FeeStructure} from '../../../../billing/billing';
import {FullFrequencyNamePipe} from '../../../../shared/pipes/full-frequency-name.pipe';
import {BillingDisplayCollectionTypePipe} from '../../../../shared/pipes/billing-display-collection-type.pipe';
import {BillingDisplayCalculationTypePipe} from '../../../../shared/pipes/billing-display-calculation-type.pipe';
import {BillingDisplayBalanceTypePipe} from '../../../../shared/pipes/billing-display-balance-type.pipe';
import {NotificationService} from '../../../../shared/services/ui/notification.service';
import {FeeStructuresService} from '../fee-structures.service';
import {FirmService} from '../../../../firm/firm.service';
import {DxDataGridComponent} from 'devextreme-angular/ui/data-grid';
import {BreadcrumbLink} from '../../../../shared/components/breadcrumbs/breadcrumbs.component';
import {select, Store} from '@ngrx/store';
import * as fromFeeStructure from '../state';
import {Observable} from 'rxjs';
import {filter, takeWhile} from 'rxjs/operators';
import * as feeStructureActions from '../state/fee-structure.actions';
import {Actions, ofType} from '@ngrx/effects';
import { MatDialog } from '@angular/material/dialog';
import {FeeStructuresCreateEditComponent} from '../fee-structures-create-edit/fee-structures-create-edit.component';
import {AccountService} from '../../../../accounts/account.service';

@Component({
  selector: 'app-billing-list-fee-structures',
  templateUrl: './fee-structures-list.component.html',
  providers: [
    FullFrequencyNamePipe,
    BillingDisplayCollectionTypePipe,
    BillingDisplayCalculationTypePipe,
    BillingDisplayBalanceTypePipe],
})
export class FeeStructuresListComponent implements OnInit, OnDestroy {

  @ViewChild('dataGrid', { static: true }) dataGrid: DxDataGridComponent;
  breadcrumbs: BreadcrumbLink[];
  feeStructures$: Observable<FeeStructure[]>;
  private componentActive = true;
  private lastRemovedFeeStructure: FeeStructure;

  constructor(
    private route: ActivatedRoute,
    private fullFrequencyNamePipe: FullFrequencyNamePipe,
    private billingDisplayCollectionTypePipe: BillingDisplayCollectionTypePipe,
    private billingDisplayCalculationTypePipe: BillingDisplayCalculationTypePipe,
    private billingDisplayBalanceTypePipe: BillingDisplayBalanceTypePipe,
    private firmService: FirmService,
    private notification: NotificationService,
    private billingFeeStructuresService: FeeStructuresService,
    private store: Store<fromFeeStructure.State>,
    private actions$: Actions,
    private dialog: MatDialog,
    private accountService: AccountService,
  ) {
    this.breadcrumbs = [
      {
        url: '/billing/nav',
        name: 'Billing'
      },
      {
        name: 'Fee Structures'
      }
    ];
  }

  feeStructures: FeeStructure[];
  isHelpWindowVisible: boolean;
  private selected: Array<FeeStructure> = [];
  isGrouped: boolean;

  getFeeStructureLink(id) {
    return '/billing/fee-id/edit/' + id;
  }

  ngOnInit() {
    this.feeStructures$ = this.store.pipe(select(fromFeeStructure.getFeeStructures));

    this.actions$.pipe(
      ofType(feeStructureActions.RemoveFeeStructureSuccess),
      takeWhile(() => this.componentActive)
    ).subscribe(() => {
      this.notification.showSuccessNotification(`Fee structure ${this.lastRemovedFeeStructure.name} was removed`);
    });

    this.actions$.pipe(
      ofType(feeStructureActions.RemoveFeeStructureFail),
      takeWhile(() => this.componentActive)
    ).subscribe((err: any) => {
      if (err.status === 403) {
        this.notification.showErrorNotification(err.data.message);
      } else {
        this.notification.showErrorNotification('There was a problem deleting fee structures. ' +
          'Please contact support: support@bridgeft.com.');
      }
      }
    );

    this.actions$.pipe(
      ofType(feeStructureActions.RemoveMultipleFeeStructureFail),
      takeWhile(() => this.componentActive)
    ).subscribe((err: any) => {
        if (err.status === 403) {
          this.notification.showErrorNotification(err.data.message);
        } else {
          this.notification.showErrorNotification('There was a problem deleting fee structures. ' +
            'Please contact support: support@bridgeft.com.');
        }
      }
    );
  }

  ngOnDestroy(): void {
    this.componentActive = false;
  }

  openHelpModal() {
    this.isHelpWindowVisible = true;
  }

  onSelection(selectedItems) {
    this.selected = selectedItems.selectedRowsData;
  }

  onContentReady(e) {
    this.isGrouped = !!e.component.columnOption('groupIndex:0');
  }

  displayFrequencyValue = (fs) => {
    return this.fullFrequencyNamePipe.transform(fs.frequency);
  }

  displayCollectionValue = (fs) => {
    return this.billingDisplayCollectionTypePipe.transform(fs.collection_type);
  }

  displayCalculationValue = (fs) => {
    return this.billingDisplayCalculationTypePipe.transform(fs.calculation_type);
  }

  displayBalanceType = (fs) => {
    return this.billingDisplayBalanceTypePipe.transform(fs.balance_type);
  }

  displayFirm = (fs) => {
    if (this.firmService.getFirm(fs.firm_id).name !== undefined) {
      return this.firmService.getFirm(fs.firm_id).name;
    } else {
      return this.firmService.getAncestorFirm(fs.firm_id).name;
    }
  }

  isFirmVisible() {
    return this.firmService.isSuperFirm();
  }

  removeFeeStructure(fs) {
    const msg = `You are deleting fee structure: ${fs.name}`;

    this.notification.displayConfirmationNotification('Are you sure?', msg, 'delete')
      .afterClosed()
      .toPromise()
      .then(result => {
        if (result) {
          this.store.dispatch(feeStructureActions.RemoveFeeStructure({feeStructure: fs}));
          this.lastRemovedFeeStructure = fs;
        }
      });
  }

  removedSelectedFeeStructures() {
    if (!this.selected || this.selected.length === 0) {
      this.notification.showInfoNotification('Select at least one fee structure');
      return;
    }

    const msg = (this.selected.length === 1) ? `You are removing fee structure: ${this.selected[0].name}`
      : `You are removing ${this.selected.length} fee structures`;

    this.notification.displayConfirmationNotification('Are you sure?', msg, 'delete')
      .afterClosed()
      .toPromise()
      .then(result => {
        if (result) {
          this.store.dispatch(feeStructureActions.RemoveMultipleFeeStructure({feeStructures: this.selected}));
        }
      });
  }

  isSelected() {
    return this.selected.length > 0;
  }

  openCreateModal() {
    this.dialog.open(FeeStructuresCreateEditComponent, {data: {}});
  }

  openEditModal(id: number) {
    this.dialog.open(FeeStructuresCreateEditComponent, {data: {id: id}});
  }

  // TODO: Temporary, should be calculated on backend
  countAssignedAccounts = (feeStructure: FeeStructure) => {
    let count = 0;

    this.accountService.accounts.forEach(account => {
      if (account.fee_structures_ids.includes(feeStructure.id)) {
        count++;
      }
    });

    return count;
  }
}

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl} from '@angular/forms';
import {TimePeriod} from './time-period.type';
import * as moment from 'moment';
import _forEach from 'lodash-es/forEach';
import DataSource from 'devextreme/data/data_source';

@Component({
  selector: 'app-atlas-time-period-dropdown',
  templateUrl: './atlas-time-period-dropdown.component.html',
  styleUrls: ['./atlas-time-period-dropdown.component.scss']
})
export class AtlasTimePeriodDropdownComponent implements OnInit {
  selectBoxOptions: DataSource;
  selectedPeriod: TimePeriod;
  standardPeriodOptions = [];
  standardPeriod: any;
  standardPeriodId: number;

  periodGroups = [
    'Annual',
    'Monthly',
    'Quarterly',
  ];

  periodOptions = [
    {
      name: 'Annual',
      options: []
    },
    {
      name: 'Monthly',
      options: []
    },
    {
      name: 'Quarterly',
      options: []
    }
  ];

  @Input() control: FormControl;
  @Input() style: any;
  @Input() lastAvailableDate: string;

  @Output() periodChange = new EventEmitter<TimePeriod>();

  constructor() { }

  ngOnInit(): void {
    this.getPeriodOptions();

    this.standardPeriod = this.periodOptions[0].options[1];

    this.periodChange.emit(this.standardPeriod);
  }

  @Input()
  get period() {
    return this.selectedPeriod;
  }

  set period(period) {
    this.selectedPeriod = period;
    this.periodChange.emit(this.selectedPeriod);
  }

  emitPeriod(event) {
    this.selectedPeriod = event.value;
    this.standardPeriod = this.standardPeriodOptions.find((option) => option.id === this.selectedPeriod);
    this.periodChange.emit(this.standardPeriod);
  }

  getPeriodOptions() {
    const ls = [];
    let i = 1;
    const formatter = 'YYYY-MM-DD';

    // Last 12 Months

    const oneYearAgo = moment(this.lastAvailableDate).subtract(1, 'years').format(formatter);

    this.periodOptions[0].options.push({
      id: 0,
      name: 'Last 12 Months',
      category: 'Annual',
      startDate: oneYearAgo,
      endDate: this.lastAvailableDate
    });

    // months
    const mo = moment().month();
    const months = [mo, mo - 1, mo - 2, mo - 3, mo - 4, mo - 5, mo - 6];

    months.forEach((month) => {
      const m = moment().month(month);
      const start = m.startOf('month').format(formatter);
      const end = m.endOf('month').format(formatter);

      this.periodOptions[1].options.push({
        id: i,
        name: m.format('MMM [of] YYYY'),
        category: 'Monthly',
        startDate: start,
        endDate: end
      });

      this.standardPeriodOptions.push(...this.periodOptions[1].options);

      i += 1;
    });


    // quarter options
    const qtr = moment().quarter();
    const quarters = [qtr, qtr - 1, qtr - 2, qtr - 3];

    quarters.forEach((q) => {
      const m = moment().quarter(q);
      const start = m.startOf('quarter').format(formatter);
      const end = m.endOf('quarter').format(formatter);

      this.periodOptions[2].options.push({
        id: i,
        name: m.format('YYYY [Q]Q'),
        category: 'Quarterly',
        startDate: start,
        endDate: end
      });

      this.standardPeriodOptions.push(...this.periodOptions[2].options);

      i += 1;
    });


    // annual options

    const currentYear = new Date().getFullYear();
    const years = [];

    for (let year = currentYear; year >= 2019; year--) {
      years.push(year);
    }

    _forEach(years, (yr, idx) => {
      if (idx === 0) {
        this.standardPeriodId = i;
      }
      const m = moment().year(yr);
      const start = m.startOf('year').format(formatter);
      const end = m.endOf('year').format(formatter);

      this.periodOptions[0].options.push({
        id: i,
        name: m.format('YYYY'),
        category: 'Annual',
        startDate: start,
        endDate: end
      });

      this.standardPeriodOptions.push(...this.periodOptions[0].options);

      i += 1;
    });

    return ls;
  }
}

<div style="width: 450px;">
  <h2 mat-dialog-title>
    Select Benchmarks
  </h2>

  <mat-dialog-content>
    <p>
      <app-atlas-multiselect
        [data]="benchmarks"
        [(value)]="selectedBenchmarks"
        valueField="id"
        displayField="name"
      >
      </app-atlas-multiselect>
    </p>

    <p>Assigns benchmark to {{ numEntities() | number }} selected account / household entities.</p>

    <p>
      <mat-checkbox [(ngModel)]="propagateHouseholdAssignments">Propagate household assignments to individual accounts</mat-checkbox>
    </p>
    <p class="help-block">
      Checking this option will cause any assignments made to a household to propagate the same benchmark
      assignment to all accounts within the selected household.
    </p>

  </mat-dialog-content>


  <div class="actions-container">
    <button mat-button color="primary" [mat-dialog-close]="false">Cancel</button>
    <app-atlas-submit-button [saving]="status.saving" (click)="save()">Save</app-atlas-submit-button>
  </div>

</div>

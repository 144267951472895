import { Pipe, PipeTransform } from '@angular/core';
import {CurrentBalanceService} from '../services/current-balance.service';
import {Household} from '../../households/household';
import {Account} from '../../accounts/account';

@Pipe({
  name: 'currentBalance'
})
export class CurrentBalancePipe implements PipeTransform {

  constructor(private currentBalanceService: CurrentBalanceService) {}

  // Takes a household or account and displays the current balance
  transform(entity: Account | Household, ...args: any[]): number {
    if (entity.is_household) {
      if (this.currentBalanceService.currentBalanceByHouseholdID[entity.id]) {
        return this.currentBalanceService.currentBalanceByHouseholdID[entity.id].total_value || 0;
      } else {
        return 0;
      }

    } else {
      if (this.currentBalanceService.currentBalanceByAccountID[entity.id]) {
        return this.currentBalanceService.currentBalanceByAccountID[entity.id].total_value || 0;
      } else {
        return 0;
      }

    }
  }

}

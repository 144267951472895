import {InvoiceListComponent} from './invoice-list/invoice-list.component';
import {RouterModule} from '@angular/router';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {SharedModule} from '../../shared/shared.module';
import {InvoiceResolver, InvoicesResolver} from './invoice.resolve';
import { DevExtremeModule} from '../../devextreme/dev-extreme.module';
import {FormsModule} from '@angular/forms';
import { InvoiceDetailComponent } from './invoice-detail/invoice-detail.component';
import {ClientInvoiceListComponent} from './client-invoice-list/client-invoice-list.component';
import {ClientInboxModule} from '../../client-inbox/client-inbox.module';


@NgModule({
  declarations: [
    InvoiceListComponent,
    InvoiceDetailComponent,
    ClientInvoiceListComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    FormsModule,
    ClientInboxModule
  ],
  exports: [
    ClientInvoiceListComponent
  ],
  providers: [InvoicesResolver, InvoiceResolver]
})
export class InvoicesModule { }

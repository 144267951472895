export class BillingAssetAdjustment {
  // tslint:disable:variable-name
  security_ids: number[] = [];
  name: string;
  firm_id: number;
  level: string;
  adjustment_type: string;
  id?: number;
  account_ids: number[] = [];
  weight: number;

  constructor(firmId: number) {
    this.firm_id = firmId;
  }
}

export class Minimum {
  name = '';
  object: string;
  firm_id: number;
  id?: number;
  value: number;
  value_type = '';
}

export class FeeStructure {
  balance_type = '';
  calculation_type = '';
  collection_type = '';
  firm_id: number;
  flat_dollar_fee: number = null;
  flat_rate: number = null;
  frequency = '';
  id?: number;
  name = '';
  quarter_cycle = 3;
  semi_annual_cycle = 6;
  annual_cycle = 12;
  slug = '';
  tiers: FeeStructureTier[] = [];
  object = 'billing.fee_structure';

  constructor(firmId: number) {
    this.firm_id = firmId;
  }
}

export class FeeStructureTier {
  charge_unit_id: number = null;
  id?: number;
  max_balance: string = null;
  min_balance = 0.00;
  object = 'billing.tier';
  rate = null;

  constructor(id?: number) {
    if (id) {
      this.charge_unit_id = id;
    }
  }
}

export class BillingGroup {
  assignments: BillingGroupAssignment[] = [];
  firm_id: number;
  household_id: number = null;
  id?: number;
  minimum_ids: number[] = [];
  name = '';
  object = 'billing.group';
  slug = '';

  constructor(firmId: number) {
    this.firm_id = firmId;
  }
}

export class BillingGroupAssignment {
  account_id: number;
  fee_location = 0;
  fee_location_option = 'S';
  group_id: number;
  id: number = null;
  object = 'billing.account_group_assignment';

  constructor(accountId: number, groupId: number) {
    this.account_id = accountId;
    this.group_id = groupId;
  }
}

export interface Assignment {
  fee_location_option: string;
  object: string;
  fee_location: number;
  account_id: number;
  id: number;
  group_id: number;
}

export interface Group {
  household_id: number;
  object: string;
  name: string;
  assignments: BillingGroupAssignment[];
  id: number;
  minimum_ids: any[];
  slug: string;
  firm_id: number;
}

export class Split {
  firm_id: number;
  id: number;
  name: string;
  percentage: number;
  splitter_name: string;
  splitter_slug = 'splitter_slug';
  object = 'billing.split';
  constructor(firmId) {
    this.firm_id = firmId;
  }
}


<mat-form-field appearance="outline" style="width:100%">
  <mat-chip-list #chipList aria-label="Selection" aria-orientation="horizontal">
      <mat-chip
        color="primary"
        *ngIf="selectedItems.length > 1 else chips"
        [selectable]="false"
        [removable]="true"
        (removed)="removeAll()"
      >
        {{selectedItems.length}} Selected
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip>

      <ng-template #chips>
        <mat-chip
          *ngFor="let item of selectedItems"
          [selectable]="false"
          [removable]="true"
          (removed)="remove(item)">
          {{ smallerText ? shortenText(item[displayField]) : item[displayField] }}
          <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip>
      </ng-template>

    <input
      [disabled]="disabled"
      [placeholder]="placeholder && selectedItems.length === 0 ? placeholder : null"
      [formControl]="control"
      [matAutocomplete]="auto"
      [matChipInputFor]="chipList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
    >
  </mat-chip-list>
  <mat-hint *ngIf="hint" style=" display: inline-block; width: 100%; word-wrap: break-word;"><p>{{hint}}</p></mat-hint>

  <mat-icon matSuffix>arrow_drop_down</mat-icon>
</mat-form-field>

<mat-autocomplete #auto="matAutocomplete"
                  [displayWith]="displaySelected"
>
  <mat-option [ngClass]="smallerText ? 'small-font' : null"
              *ngFor="let item of filteredItems$ | async | slice:0:25"
              [value]="item[valueField]"
              (keydown.enter)="optionClicked($event, item)"
  >
    <div (click)="optionClicked($event, item)">
      <mat-checkbox [checked]="isChecked(item)" (change)="toggleSelection(item)" (click)="$event.stopPropagation()">
        {{ item[displayField] }}
      </mat-checkbox>
    </div>
  </mat-option>
</mat-autocomplete>

export class Coefficient {
  weight: number = 5.00;
  object = 'reporting.benchmark_coefficient';
  // tslint:disable-next-line:variable-name
  benchmark_id: number;
  id?: number;
  // tslint:disable-next-line:variable-name
  index_id: number;
  constructor(benchmarkId: number, indexID: number) {
    this.benchmark_id = benchmarkId;
    this.index_id = indexID;
  }

}

export class Benchmark {
  name = '';
  object = 'reporting.benchmark';
  firm_id: number;
  id: number;
  coefficients: Coefficient[] = [];
  slug = '';

  constructor(firmId) {
    this.firm_id = firmId;
  }
}

import {Firm} from '../../firm';
import * as FirmActions from './firm.actions';
import {createEntityAdapter, EntityState} from '@ngrx/entity';
import {EntityAdapter} from '@ngrx/entity/src/models';
import {Action, createReducer, on} from '@ngrx/store';
import {CallState} from '../../../state/types';
import {InitialCallState} from '../../../state/helpings/initial-call-state';

export interface FirmState  extends EntityState<Firm> {
  callState: CallState;
  updateCallState: CallState;
}
export const adapter: EntityAdapter<Firm> = createEntityAdapter<Firm>();

const initialState: FirmState = adapter.getInitialState({
  callState: {...new InitialCallState()},
  updateCallState: {...new InitialCallState()}
});

const firmReducer = createReducer(
  initialState,
  on(FirmActions.Load, (state) => {
    const callState = {...state.callState, loading: true};
    return {...state, callState};
  }),
  on(FirmActions.LoadSuccess, (state, {Firms: firms}) => {
    const callState = {loading: false, loaded: true, error: null};
    return {...adapter.addAll(firms, state), callState};
  }),
  on(FirmActions.LoadFail, (state, {error}) => {
    const callState = {loading: false, loaded: false, error};
    return {...state, callState};
  }),
  on(FirmActions.UpdateFirm, (state) => {
    const updateCallState = {...state.updateCallState, loading: true};
    return {...state, updateCallState};
  }),
  on(FirmActions.UpdateFirmSuccess, (state, {Firm: firm}) => {
    const updateCallState = {loading: false};
    return {...adapter.upsertOne(firm, state), updateCallState};
  }),
  on(FirmActions.UpdateFirmFail, (state) => {
    const updateCallState = {loading: false};
    return {...state, updateCallState};
  }),
);

export function reducer(state: FirmState | undefined, action: Action) {
  return firmReducer(state, action);
}

// get the selectors
const {
  selectAll,
} = adapter.getSelectors();

// select the array of users
export const selectAllFirms = selectAll;

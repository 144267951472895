
export const environment = {
    production: false,
    hmr: false,
    hostUrl: 'https://staging.atlas.bridgeft.com',

    env: 'staging',

    searchCache: true,
    searchCacheTTL: 259200000,

    churnZeroAppKey: '',

    pendoApiKey: '',

    sentryDSN: 'https://f720ba461b834c2194819cb0811765d3@o35604.ingest.sentry.io/6744987',

    region: 'us-east-1',
    identityPoolId: 'us-east-1:7575e87f-c7d9-4666-ab7b-9ad9ab3fa764',
    userPoolId: 'us-east-1_pLolGBKKJ',
    clientId: '3lrga6dcmvivjurb7ku5qnenbj',

    apiV2Url: 'https://staging.api.bridgeft.com/v2',
    websocketUrl: ' wss://07lff3s7nc.execute-api.us-east-1.amazonaws.com/staging/',

    apiWealthbox: 'https://api.crmworkspace.com/v1',
    wealthboxApiKey: 'c202bcec4497f39ddbf68223e91d452a',

    apiPortformer: 'https://app.portformer.com/api/v2',
    portformerAppKey: 'z+Aj8EKo7UfxEp3zGhHJTNfFCqtGINKGxonS8b3egss=',

    intercomKey: '',

    plaidServiceUrl: 'https://staging.api.bridgeft.com/v2/integrations/plaid',

    insightsApiUrl: 'https://staging.insights.api.bridgeft.com/',

    stratifiExternalUrl: 'https://sandbox.stratifi.com/o/authorize',
    stratifiClientId: 'wESUw6y1aXFXrgtmiKEK13HHcfYJejzO2N0oQUCi'
};

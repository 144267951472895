<div style="width: 600px">
  <h2 mat-dialog-title>Email to Client</h2>

  <mat-dialog-content>

    <div fxLayout="column"
         gdGap="5px"
         gdColumns="calc(100%-10px"
         [formGroup]="emailForm"
    >

      <label class="detail-label">
        Client
      </label>

      <mat-form-field appearance="outline">
        <input matInput placeholder="Select a Client" formControlName="client" [matAutocomplete]="auto">

        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayClient">
          <mat-option *ngFor="let client of filteredClients$ | async | slice:0:20" [value]="client">
            {{client.first_name}} {{client.last_name}} ({{client.email}})
          </mat-option>
        </mat-autocomplete>

        <mat-icon matSuffix>arrow_drop_down</mat-icon>
      </mat-form-field>

      <label class="detail-label">
        Verify Email
      </label>

      <mat-form-field appearance="outline">
        <input matInput placeholder="Verify Email" formControlName="verifyEmail" email>
        <mat-error *ngIf="emailForm.controls['verifyEmail'].hasError('MatchEmail')">Emails must match</mat-error>
      </mat-form-field>

    </div>

    <div>
      <label for="messageArea" class="detail-label">
        Message (Optional)
      </label>

      <mat-form-field style="width: 100%" appearance="outline">
        <textarea id="messageArea" matInput placeholder="Enter an optional message" formControlName="message" (change)="this.emailForm.controls['message'].setValue($event.target.value)">

        </textarea>
      </mat-form-field>
    </div>

    <div *ngIf="messageType === 'R'">
      <label class="detail-label">
        Reports to Send
      </label>
      <dx-data-grid
        [dataSource]="sendables"
        [allowColumnReordering]="false"
        style="max-height: 200px"
      >
        <dxi-column
          caption="Name"
          dataField="name"
        >
        </dxi-column>

        <dxi-column
          caption="Start Date"
          dataField="start_date"
        >
        </dxi-column>

        <dxi-column
          caption="End Date"
          dataField="end_date"
        >
        </dxi-column>

        <dxo-selection mode="none"></dxo-selection>
      </dx-data-grid>
    </div>

    <div *ngIf="messageType === 'I'">
      <label class="detail-label">
        Invoices to Send
      </label>
      <dx-data-grid
        [dataSource]="sendables"
        [allowColumnReordering]="false"
        style="max-height: 200px"
      >
        <dxi-column
          caption="Name"
          dataField="billing_group.name"
          [calculateCellValue]="getGroupName"
        >
        </dxi-column>

        <dxi-column
          caption="Invoice Date"
          dataField="billing_date"
        >
        </dxi-column>

        <dxi-column
          caption="Created Date"
          dataField="created_date"
        >
        </dxi-column>

        <dxo-selection mode="none"></dxo-selection>
      </dx-data-grid>
    </div>



  </mat-dialog-content>

  <div class="actions-container">
    <button mat-button color="primary" [mat-dialog-close]>Cancel</button>
    <button *ngIf="messageType === 'R'" mat-flat-button color="primary" [disabled]="!emailForm.valid" (click)="sendReport()">
      <mat-icon *ngIf="sending">
        <mat-spinner color="accent" mode="indeterminate" [diameter]="20"></mat-spinner>
      </mat-icon>
      Send
    </button>

    <button *ngIf="messageType === 'I'" mat-flat-button color="primary" [disabled]="!emailForm.valid" (click)="sendInvoice()">
      <mat-icon *ngIf="sending">
        <mat-spinner color="accent" mode="indeterminate" [diameter]="20"></mat-spinner>
      </mat-icon>
      Send
    </button>
  </div>
</div>

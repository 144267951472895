import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FullFrequencyNamePipe } from './full-frequency-name.pipe';
import {FullMonthNamePipe} from './full-month-name.pipe';
import {TimeAgoPipe} from './time-ago.pipe';
import { AccountStatusDisplayPipe } from './account-status-display.pipe';
import { AbbreviatedAmountPipe } from './abbreviated-amount.pipe';
import { DisplayAmountPipe } from './display-amount.pipe';
import { DisplayAssetClassPipe } from './display-asset-class.pipe';
import { DisplayTransactionCodePipe } from './display-transaction-code.pipe';
import { CacDisplayClassificationPipe } from './cac-display-classification.pipe';
import { YesNoPipe } from './yes-no.pipe';
import { DisplayRelationshipTypePipe } from './display-relationship-type.pipe';
import { DisplayPipelineSyncStatePipe } from './display-pipeline-sync-state.pipe';
import { JobStatusPipe } from './job-status.pipe';
import {BillingDisplayCalculationTypePipe} from './billing-display-calculation-type.pipe';
import { DisplayBytesPipe } from './display-bytes.pipe';
import { FileSharingDisplayLinkPermissionsPipe } from './file-sharing-display-link-permissions.pipe';
import { ApplyDataMaskPipe } from './apply-data-mask.pipe';
import {DisplayCustodianPipe} from './display-custodian.pipe';
import { BillingDisplayFrequencyPipe } from './billing-display-frequency.pipe';
import {BillingDisplayCollectionTypePipe} from './billing-display-collection-type.pipe';
import {BillingDisplayBalanceTypePipe} from './billing-display-balance-type.pipe';
import { BalanceGroupValuePipe } from './balance-group-value.pipe';
import { FeeGroupValuePipe } from './fee-group-value.pipe';
import { EffectiveRateGroupValuePipe } from './effective-rate-group-value.pipe';
import {DisplayQuarterCyclePipe} from './display-quarter-cycle.pipe';
import {BillingDisplayPaymentSourcePipe} from './billing-display-payment-source.pipe';
import { DisplaySplitterSlugPipe } from './display-splitter-slug.pipe';
import { DisplayMinimumValuetypePipe } from './display-minimum-valuetype.pipe';
import {CurrentBalancePipe} from './current-balance.pipe';
import {BillingDisplayFeeLocationPipe} from '../../billing/pipes/billing-display-fee-location.pipe';
import {DescribeAssetAdjustmentPipe} from '../../billing/pipes/describe-asset-adjustment.pipe';
import { FinancialStandardPipe } from './financial-standard.pipe';
import { DisplayRiskCategoryPipe } from './display-risk-category.pipe';
import { CopyPipe } from './copy.pipe';
import { TimeSeriesNamePipe } from './insights/time-series-name.pipe';
import { TsPayloadUnitPipe } from './insights/ts-payload-unit.pipe';
import { MaskManualAccountPipe } from './mask-manual-account.pipe';

@NgModule({
  declarations: [
    FullFrequencyNamePipe,
    FullMonthNamePipe,
    TimeAgoPipe,
    AccountStatusDisplayPipe,
    AbbreviatedAmountPipe,
    DisplayAmountPipe,
    DisplayAssetClassPipe,
    DisplayTransactionCodePipe,
    CacDisplayClassificationPipe,
    YesNoPipe,
    DisplayRelationshipTypePipe,
    DisplayPipelineSyncStatePipe,
    JobStatusPipe,
    DisplayBytesPipe,
    FileSharingDisplayLinkPermissionsPipe,
    ApplyDataMaskPipe,
    DisplayCustodianPipe,
    BillingDisplayFrequencyPipe,
    BillingDisplayCollectionTypePipe,
    BillingDisplayBalanceTypePipe,
    BalanceGroupValuePipe,
    FeeGroupValuePipe,
    EffectiveRateGroupValuePipe,
    DisplayQuarterCyclePipe,
    BillingDisplayCalculationTypePipe,
    BillingDisplayPaymentSourcePipe,
    DisplaySplitterSlugPipe,
    DisplayMinimumValuetypePipe,
    BillingDisplayBalanceTypePipe,
    CurrentBalancePipe,
    BillingDisplayFeeLocationPipe,
    DescribeAssetAdjustmentPipe,
    FinancialStandardPipe,
    DisplayRiskCategoryPipe,
    CopyPipe,
    TimeSeriesNamePipe,
    TsPayloadUnitPipe,
    MaskManualAccountPipe,
  ],
  imports: [
    CommonModule
  ],
  exports: [
    FullFrequencyNamePipe,
    FullMonthNamePipe,
    TimeAgoPipe,
    AccountStatusDisplayPipe,
    AbbreviatedAmountPipe,
    DisplayAmountPipe,
    DisplayAssetClassPipe,
    DisplayTransactionCodePipe,
    CacDisplayClassificationPipe,
    YesNoPipe,
    DisplayRelationshipTypePipe,
    DisplayPipelineSyncStatePipe,
    JobStatusPipe,
    DisplayBytesPipe,
    FileSharingDisplayLinkPermissionsPipe,
    ApplyDataMaskPipe,
    DisplayCustodianPipe,
    BillingDisplayFrequencyPipe,
    BillingDisplayCollectionTypePipe,
    BillingDisplayBalanceTypePipe,
    BalanceGroupValuePipe,
    FeeGroupValuePipe,
    EffectiveRateGroupValuePipe,
    DisplayQuarterCyclePipe,
    BillingDisplayCalculationTypePipe,
    BillingDisplayPaymentSourcePipe,
    DisplaySplitterSlugPipe,
    DisplayMinimumValuetypePipe,
    BillingDisplayBalanceTypePipe,
    CurrentBalancePipe,
    BillingDisplayFeeLocationPipe,
    DescribeAssetAdjustmentPipe,
    FinancialStandardPipe,
    DisplayRiskCategoryPipe,
    CopyPipe,
    TimeSeriesNamePipe,
    TsPayloadUnitPipe,
    MaskManualAccountPipe,
  ],
})
export class PipesModule { }
